import React, { Component } from "react";
import { Link } from "react-router-dom";
import LanguageSelector from "./language-toggle/language-toggle";
import "./topbar.css";
class TopBar extends Component {
  render() {
    return (
      <div className="top" id="topbar-desktop">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <ul className="flat-infomation">
                <li className="phone">
                  <p className="phone02">Call us: +968 91160006</p>
                </li>
                <li className="email">
                  <p className="phone02">
                    {" "}
                    <a
                      href="mailto:albadiagreen@gmail.com"
                      style={{ color: "black", curser: "pointer" }}
                    >
                      Email: albadiagreen@gmail.com
                    </a>
                  </p>
                </li>
              </ul>
              <div className="flat-questions">
                <LanguageSelector />
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TopBar;
