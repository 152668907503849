import React, { useState } from "react";
import { Button } from "antd";
import "./button.css";

import i18n from "../../translations/i118";

export default function App(props) {
  const buttonStyle = {
    marginRight: "25px",
    backgroundColor: "black",
    color: "white",
  };

  const [closeMenu, setcloseMenu] = useState(props.closeMenu);

  const [language, setLanguage] = useState("en");

  const handleOnclick = (e) => {
    e.preventDefault();
    setLanguage(e.target.value);
    console.log(e.target.value);
    i18n.changeLanguage(e.target.value);
    setcloseMenu(!closeMenu);
  };
  return (
    <div style={{ gap: "5px" }}>
      <button
        value="ar"
        onClick={handleOnclick}
        className="button-6"
        style={{ marginRight: "20px" }}
      >
        عربى
      </button>
      <button value="en" onClick={handleOnclick} className="button-6">
        English
      </button>
    </div>
  );
}
