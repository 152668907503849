import React from "react";
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { MdConstruction } from "react-icons/md";
import { GiMineTruck } from "react-icons/gi";
import { Avatar, Card } from "antd";
const { Meta } = Card;
const App = (props) => (
  <Card
    hoverable
    style={{
      width: 300,
    }}
    cover={
      //   <img
      //     alt="example"
      //     src="https://cdn.pixabay.com/photo/2017/08/03/21/37/construction-2578410_960_720.jpg"
      //   />
      <GiMineTruck size="100px" style={{ padding: "10px" }} color="#EF9A53" />
    }
  >
    <Meta
      title={props.title}
      description={
        <p style={{ padding: "10px", textAlign: "start" }}>
          {props.description}{" "}
        </p>
      }
    />
  </Card>
);
export default App;
