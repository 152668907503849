import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../../translations/i118";

function BasicExample() {
  const { t } = useTranslation();

  return (
    <Navbar bg="light" expand="lg" fixed>
      <Container>
        <Navbar.Brand href="#home">
          <div style={{ display: "flex" }}>
            <img
              src="assets/logo1.jpeg"
              width="180"
              height="90"
              // className="d-inline-block align-top"
              alt="React Bootstrap logo"
              style={{ marginTop: "-35px", marginBottom: "125px" }}
            />
            {/* <h2> AL BADIA GREEN OASIS TRADING EST.</h2> */}
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="justify-content-end flex-grow-1 pe-3">
            <Nav.Link href="#home">
              {" "}
              <Link to="/">{t("home_button")}</Link>
            </Nav.Link>
            <Nav.Link href="#link">
              <Link to="aboutus">{t("about_us_button")}</Link>
            </Nav.Link>
            <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link href="#link">Link</Nav.Link>
            <NavDropdown title="Dropdown" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default BasicExample;
