export const TRANSLATIONS_ARABIC = {
  welcome_to_home: "مرحبا بك في",
  company_name: "واحة البادية الخضراء للتجارة",
  tagline: "نقوم بتأجير الشاحنات وخدمات البناء",

  //buttons
  home_button: "الصفحة الرئيسية",
  about_us_button: "معلومات عنا",
  services_button: "خدماتنا",
  gallary_button: "الاستديو",
  contact_button: "اتصل بنا",
  certificate_button: "الشهادات",

  about_us: "معلومات عنا",
  profile_home: `تأسست شركة واحة البادية الخضراء للتجارة كشركة نقل البضائع والمعدات في سلطنة عمان لتوفير النقل البري وتأجير المعدات واستيراد وتوريد البضائع وأعمال البناء المدني.  سجلت شركة البادية الخضراء للتجارة كمورد معتمد لنظام تسجيل الموردين المشترك (JSRS) في صناعة النفط والغاز العمانية (JSRS) ، واليوم ، يمكن لواحة البادية  الخضراء للتجارة تلبية احتياجات النقل للعملاء المرموقين في جميع أنحاء سلطنة عمان.  بجانب خدمات النقل لدينا ، نقوم أيضًا بأعمال البناء ونتولى دور المقاول الرئيسي للمشاريع الصغيرة والمتوسطة الحجم.`,
  vision: "رؤية",
  mission: "بعثة",
  mission_text: `لبناء علاقات طويلة الأمد مع عملائنا  وتقديم خدمات عملاء استثنائية من خلال متابعة الأعمال من خلال الابتكار والتكنولوجيا المتقدمة`,
  vision_text: `لتقديم خدمات عالية الجودة تفوق توقعات عملائنا الكرام`,

  // homepage message_from_chairman

  message_from_chairman: "رسالة من الرئيس",
  message_description: `هدفنا هو تزويد عملائنا بتجربة "أنا مطمئن" عندما يتم اختيارنا لتنفيذ مشاريعهم.  يضمن تركيزنا على إجراءات الاتصال والمتابعة الواضحة أن تكون أهداف العميل أولوية قصوى في تخطيط وتنفيذ جميع عملياتنا`,
  shaike_name: "محمد مبخوت سعيد جداد",
  shaike_designation: "المدير العام",
  //home_page_services_section

  our_services: `خدماتنا`,
  services_heading: ` ما يمكن أن نقدمه لك`,
  construction_home: "أعمال البناء",
  transport_of_goods: "نقل البضائع وتأجير المعدات",
  water_treatment_plant: "محطة معالجة المياه",

  transport_description: `يمكن أن تلبي شركة الواحة البادية الخضراء للتجارة احتياجات النقل البري لعملائنا الكرام بجودة سريعة.  نحن قادرون على توفير مجموعة واسعة من المركبات الثقيلة مثل Tipper Truck ، Skip Loader Truck with Skip Boxes ، صهريج مياه الشرب (8000 جالون) ، ناقلة مياه الصرف الصحي (8000 جالون) على أساس التأجير.  تشمل خدمة النقل لدينا.  1- نقل التربة والرمال والصخور 2- نقل المياه العذبة 3- نقل المياه غير الصالحة للشرب القابلة لإعادة التدوير 4- نقل النفايات والصرف الصحي`,
  water_description: `لقد قمنا مؤخرًا بتأسيس محطة معالجة المياه كخدمة إضافية لتوفير مياه الشرب لعملائنا الكرام.  يهدف المصنع إلى معالجة المياه التي يتم ضخها من الآبار المحلية.`,

  construction_description: `واحة البادية  الخضراء. يمكن أن تتولى دور المقاول الرئيسي للمشاريع الصغيرة والمتوسطة الحجم وتؤدي خدمات إدارة المشاريع.  يمكننا أيضًا تقديم الحلول الهندسية كخدمات ذات قيمة مضافة لعملائنا.`,

  //home_page_Health and Safty

  health_safty_policy_home: "سياسة الصحة والسلامة",
  health_safty_description:
    "تلتزم إدارة  واحة البادية الخضراء بشدة بسياسة تمكن من تنفيذ جميع أنشطة العمل بأمان ، مع الاخذ بالتدابير الملزمة لإزالة المخاطر على صحة وسلامة ورفاهية العمال  المتعاقدين والزوار المصرح لهم وأي شخص آخر قد يتأثر بعملياتنا.",
  health_safty_description2:
    "نسعى للتعاون من جميع العمال والعملاء والأشخاص الآخرين.  نحن نشجع الاقتراحات لتحقيق أهدافنا المتعلقة بالصحة والسلامة لخلق بيئة عمل آمنة مع معدل صفر للحوادث.  تنطبق هذه السياسة على جميع العمليات والوظائف التجارية ، بما في ذلك المواقف التي يُطلب فيها من العمال العمل خارج الموقع",
  award_divider: "الجوائز والإنجازات",

  //cirtificates

  JSRS: "شهادة الموافقة JSRS",

  //contact page

  address: "عنوان العمل",
  address_text: `ثمريت - محافظة ظفار ص. ب: 22 ، الرمز البريدي: 222 ، سلطنة عمان`,
  phone: "رقم الهاتف",
  email: "البريد الإلكتروني",

  //About us page
  history_philosophy: "التاريخ والفلسفة",
  history01: `مؤسسة واحة  البادية الخضراء  للتجارة.  تأسست كشركة إنشاءات مدنية في محافظة ظفار في 23 أغسطس 2017 ، لتقديم أعمال البناء المدني وتأجير المعدات.  وفي وقت لاحق في 28 فبراير 2018 ، تم تسجيلها كمورد معتمد لنظام تسجيل الموردين المشترك (JSRS) في صناعة النفط والغاز العمانية. اليوم، واحة الباديه الخضراء للتجارة  يمكن أن تتولى دور المقاول الرئيسي للمشاريع الصغيرة والمتوسطة الحجم وتؤدي خدمات إدارة المشاريع.  يمكننا أيضًا تقديم الحلول الهندسية كخدمات ذات قيمة مضافة لعملائنا`,
  history02: `
  هدفنا هو تزويد عملائنا بتجربة "أنا مطمئن" عندما يتم اختيارنا لتنفيذ مشاريعهم. يضمن تركيزنا على إجراءات الاتصال والمتابعة الواضحة أن تكون أهداف العميل أولوية قصوى في تخطيط وتنفيذ جميع عملياتنا.`,
  //-------stratagy section
  company_startagy: `استراتيجية الشركة`,
  purpose: `غاية`,
  purpous_text: `أن نكون رائدين في صناعة البناء المدني من خلال تقديم خدمات معززة وعلاقات وربحية`,
  core_values: `القيم الجوهرية`,
  core_values_text: `نحن نؤمن بمعاملة عملائنا باحترام وإيمان ، وننمو من خلال الإبداع والاختراع والابتكار ، وندمج الصدق والنزاهة وأخلاقيات العمل في جميع جوانب عملنا`,
  goals: `الأهداف`,
  goals_text: `التوسع الإقليمي في مجال الإنشاءات المدنية وتطوير قاعدة قوية من العملاء الرئيسيين ، وزيادة أصول واستثمارات الشركة لدعم تطوير الخدمات ، وبناء سمعة طيبة في مجال أعمال البناء والإدارة وأن تصبح لاعباً رئيسياً في  الصناعة.`,

  scopeOfWork: `يمكن أن تلبي شركة الواحة البادية الخضراء للتجارة احتياجات النقل البري لعملائنا الكرام بجودة سريعة. نحن قادرون على توفير مجموعة واسعة من المركبات الثقيلة مثل Tipper Truck ، Skip Loader Truck with Skip Boxes ، صهريج مياه الشرب (8000 جالون) ، ناقلة مياه الصرف الصحي (8000 جالون) على أساس التأجير. تشمل خدمة النقل لدينا. 1- نقل التربة والرمال والصخور 2- نقل المياه العذبة 3- نقل المياه غير الصالحة للشرب القابلة لإعادة التدوير 4- نقل النفايات والصرف الصحي`,

  work: "مجال العمل",
  //equipmentTable

  equipment: "شاحنات متاحة للإيجار",
  dozer: "الجرار",
  shoval: `مجرفة`,
  grader: `ممهدة`,
  trailerTruck: `مقطورة شاحنة`,
  skippTruck: `تخطي شاحنة`,
  tipperTruck: `شاحنة قلاب`,
  waterTanker1: `
صهريج مياه 8000 جالون (ازرق)`,
  waterTanker2: `
صهريج مياه 8000 جالون (اخضر)`,
  waterTanker3: `ناقلة مياه الصرف الصحي 8000 جالون (أصفر)`,
  waterTreatmentPlant: `محطة لمعالجة المياه`,
  nameofEquipmwnt: `الاسم واسم الجهاز والمركبة`,
  num: `رقم`,
  //footer

  logoTag: `مؤسسة واحة البادية الخضراء للتجارة. تجري أعمال البناء المدني وكذلك تأجير المعدات وكمقاول من الباطن المدني لشركة النفط والغاز العمانية`,
  addressLine1: `ثمريت - محافظة ظفار`,
  line2: `صندوق بريد: 22`,
  line3: `بريدي
  الكود: 222`,
  line4: `سلطنة عمان`,
  HeadOffice: `
  المكتب الرئيسي`,
  contact01: `
  عقد`,

  // speacial buttons

  view_more: `عرض المزيد`,
  read_more: `أقرا المزيد`,
};
