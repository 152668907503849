import React, { useState } from "react";
import { Space, Table, Tag } from "antd";
import { Divider } from "antd";
import { useTranslation } from "react-i18next";
import "../../translations/i118";
import "./table.css";
const App = () => {
  const { t } = useTranslation();

  const [imgName, setImageName] = useState("Skipp-Truck.jpeg");

  const imgStyle = {
    width: "600px",
    height: "400px",
    alignItems: "space-between",
    justifyContent: "space-between",
    padding: "20px",
    marginTop: "65px",
    marginLeft: "65px",
    objectFit: "cover",
    border: "5px solid #EAEAEA",
    borderRadius: "10px",
  };

  const columns = [
    {
      title: t("nameofEquipmwnt"),
      dataIndex: "name",
      key: "name",
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            setImageName(record.key);
          },
        };
      },
      render(text, record) {
        return {
          props: {
            style: { cursor: "pointer" },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: t("num"),
      dataIndex: "No",
      key: "No",
    },
  ];

  const data = [
    // {
    //   key: "Dozer",
    //   name: t("dozer"),
    //   No: 2,
    // },
    // {
    //   key: "Shoval",
    //   name: t("shoval"),
    //   No: 3,
    // },
    // {
    //   key: "Grader",
    //   name: t("grader"),
    //   No: 3,
    // },

    {
      key: "Skipp-Truck.jpeg",
      name: t("skippTruck"),
      No: 2,
    },
    {
      key: "Tipper-Truck.jpeg",
      name: t("tipperTruck"),
      No: 5,
    },

    {
      key: "tanker-blue.jpeg ",
      name: t("waterTanker1"),
      No: 2,
    },
    {
      key: "Water Tanker 8000 gallon(green) ",
      name: t("waterTanker2"),
      No: 1,
    },

    {
      key: "Tanker-Yellow.jpeg",
      name: t("waterTanker3"),
      No: 1,
    },
    {
      key: "wtp.png",
      name: t("waterTreatmentPlant"),
      No: 1,
    },
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "space-between",
          justifyContent: "space-between",
        }}
      >
        <div styele={{ margin: "50px" }} className="table-images">
          <img src={`assets/table-images/${imgName}`} alt="" style={imgStyle} />
        </div>

        <Space />
        <div className="table-1">
          {" "}
          <Table columns={columns} dataSource={data} pagination={false} />
        </div>
      </div>
    </>
  );
};
export default App;
