import React from "react";
import { Row, Col, Divider } from "antd";
// import AnimatedText from "../chairmans-message/text-animation";
import { useTranslation } from "react-i18next";
import "../../translations/i118";
import "./chairmans.css";
import { RiDoubleQuotesL, RiDoubleQuotesR } from "react-icons/ri";

import { bounce } from "react-animations";
import Radium, { StyleRoot } from "radium";

const ChairmansMessage = () => {
  const { t } = useTranslation();

  const styles = {
    bounce: {
      animation: "x 3s",
      animationName: Radium.keyframes(bounce, "bounce"),
    },
  };

  return (
    <>
      <div className="Chairmans-Message" style={styles.bounce}>
        <Row span={24}>
          <div
            style={{
              // backgroundColor: "#F7F7F7",
              width: "100%",
              padding: "0px",
              marginBottom: "-30px",
            }}
          >
            <Divider orientation="left">
              <h2
                style={{
                  fontWeight: "normal",
                  color: "#393E46",
                  fontFamily: "Montserrat",
                  fontSize: "25px",
                }}
              >
                {t("message_from_chairman")}
              </h2>
            </Divider>
          </div>
        </Row>
        <Row>
          <Col span={8}>
            <div style={{ padding: "50px", backgroundColor: "white" }}>
              <img
                src="assets/arbab-img.jpeg"
                alt="pic"
                style={{
                  width: "500px",
                  height: "500px",
                  objectFit: "cover",
                  marginLeft: "-50px",
                  borderRadius: "10px",
                  border: "8px solid #F7F7F7",
                  padding: "5px",
                  margin: "15px",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              />
              <div className="Arbab-Name">
                <span
                  style={{
                    fontSize: "25px",
                    textAlign: "start",
                    fontWeight: "bold",
                    fontFamily: "Exo",
                    padding: "15px",
                    color: "rgba(0, 0, 0,.7)",
                  }}
                >
                  {" "}
                  {t("shaike_name")}
                </span>
                <br />
                <span
                  style={{
                    fontSize: "18px",
                    textAlign: "Start",
                  }}
                >
                  {" "}
                  {t("shaike_designation")}
                </span>
              </div>
            </div>
          </Col>
          <Col span={16} style={{ backgroundColor: "", color: "black" }}>
            <div
              style={{
                padding: "100px",
              }}
            >
              <p
                style={{
                  textAlign: "start",
                  fontSize: "25px",

                  padding: "50px",
                  marginTop: "100px",
                  fontFamily: "Roboto",

                  color: "black",
                }}
              >
                <RiDoubleQuotesL style={{ marginRight: "20px" }} />
                {t("message_description")}
                <RiDoubleQuotesR style={{ marginLeft: "20px" }} />
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ChairmansMessage;
