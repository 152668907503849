import React from "react";
import { MDBCarousel, MDBCarouselItem } from "mdb-react-ui-kit";
import "./hero.css";

export default function App() {
  return (
    <div className="mobile-carosal">
      <MDBCarousel>
        <MDBCarouselItem
          className="w-100 d-block"
          itemId={1}
          src="assets/stock-img/img6.jpg"
          alt="..."
        >
          <h1>WELCOME TO AL BADIA GREEN OASIS TRADING EST.</h1>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </MDBCarouselItem>
        <MDBCarouselItem
          className="w-100 d-block"
          itemId={2}
          src="assets/truck/truck-row1.jpeg"
          alt="..."
        >
          <h1>WELCOME TO AL BADIA GREEN OASIS TRADING EST.</h1>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </MDBCarouselItem>
        <MDBCarouselItem
          className="w-100 d-block"
          itemId={3}
          src="assets/stock-img/img1.jpg"
          alt="..."
        >
          <h1>WELCOME TO AL BADIA GREEN OASIS TRADING EST.</h1>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </MDBCarouselItem>
      </MDBCarousel>
    </div>
  );
}
