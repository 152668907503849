import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import HumbergurButton from "../../components/mobileview/humberger";
import "./topbar.css";
export default function ButtonAppBar() {
  return (
    <div className="mobileTopBar">
      <Box sx={{ flexGrow: 1 }} id="mobileTopBar">
        <AppBar
          position="fixed"
          sx={{ backgroundColor: "black", height: "100px" }}
        >
          <Toolbar>
            <HumbergurButton />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant="h"
                component="div"
                sx={{
                  flexGrow: 1,
                  marginTop: "15px",
                  marginLeft: "32px",
                  fontFamily: "Noto Kufi Arabic",
                  fontSize: "22px",
                  fontWeight: "bold",
                }}
              >
                {" "}
                واحة البادية الخضراء للتجار
              </Typography>
              <Typography
                variant="h"
                component="div"
                sx={{
                  flexGrow: 1,
                  marginTop: "0px",
                  marginLeft: "45px",
                  fontFamily: "Slabo 27px",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                {" "}
                AL BADIA GREEN OASIS TRADING
              </Typography>
              {/* <div
                style={{ padding: "5px", marginTop: "5px", display: "flex" }}
              >
                <Typography
                  variant="h"
                  component="div"
                  sx={{
                    flexGrow: 1,

                    fontFamily: "Noto Kufi Arabic",
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                >
                  Call us: +968 91160006
                </Typography>
                <Typography
                  variant="h"
                  component="div"
                  sx={{
                    flexGrow: 1,

                    fontFamily: "Noto Kufi Arabic",
                    fontSize: "12px",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  <a
                    href="mailto:oman91160006@gmail.com"
                    style={{ color: "white" }}
                  >
                    Email: oman91160006@gmail.com
                  </a>
                </Typography> */}
              {/* </div> */}
            </div>
          </Toolbar>
        </AppBar>
      </Box>
    </div>
  );
}
