import React from "react";
import { MDBCarousel, MDBCarouselItem } from "mdb-react-ui-kit";

export default function App() {
  return (
    <MDBCarousel fade>
      <MDBCarouselItem
        // className="w-100 d-block"
        itemId={1}
        src="assets/service-imges/gandw.jpg"
        alt="..."
        style={{ height: "350px", ObjectFit: "cover" }}
      />
      <MDBCarouselItem
        className="w-100 d-block"
        itemId={2}
        src="https://media.istockphoto.com/id/1221344103/photo/a-40-ton-truck-carries-construction-sand-on-the-highway-concept-of-overload-on-the-roads-and.jpg?s=612x612&w=0&k=20&c=vRBxITF3z7unzwLE13Ljf7aIyf8SwWEG9zBQca5mm8s="
        alt="..."
        style={{ height: "350px", objectFit: "cover" }}
      />
      <MDBCarouselItem
        className="w-100 d-block"
        itemId={3}
        src="assets/truck/truck5.jpeg"
        alt="..."
        style={{ height: "350px", objectFit: "cover" }}
      />
    </MDBCarousel>
  );
}
