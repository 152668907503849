import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./services.css";
import { useTranslation } from "react-i18next";
import "../../translations/i118";

const Services = () => {
  const { t } = useTranslation();

  const data = {
    titleServices: [
      {
        id: 1,
        title: t("services_heading"),
      },
    ],
    imagebox: [
      {
        id: "3",
        imgsrc:
          "https://media.istockphoto.com/id/1198872661/photo/middle-eastern-male-construction-professionals-onsite.jpg?s=612x612&w=0&k=20&c=sxOWae1yHEZAMEuY7jPmDyECQ3bEYdu0IjFDxm7Ha8U=",
        title: t("construction_home"),
        description: t("construction_description"),
      },
      {
        id: "2",
        imgsrc:
          "https://media.istockphoto.com/id/1345400723/photo/modern-wastewater-treatment-plant-tanks-for-aeration-and-biological-purification-of-sewage-at.jpg?s=612x612&w=0&k=20&c=ARHJ-kupeUzPxGefrAdmHsRNiZn5eLx4dx4H0W8Vzl0=",
        title: t("water_treatment_plant"),
        description: t("water_description"),
      },

      {
        id: "1",
        imgsrc:
          "https://media.istockphoto.com/id/1343491755/photo/worker-using-walkie-talkie-in-commercial-dock.jpg?s=612x612&w=0&k=20&c=TmRh_EUEqXjwmenjj69jLlMNid2_R3lMv1Dhkk7Hzxg=",
        title: t("transport_of_goods"),
        description: t("transport_description"),
      },
    ],
  };

  const [width, setWidth] = useState(window.innerWidth);
  const [serviceData, setServiceData] = useState(data);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  const reverseArray = (obj) => {
    const arr = obj.imagebox;
    const reversedArray = arr.reverse();

    if (isMobile) {
      const newObj = { ...obj, imagebox: reversedArray };
      setServiceData(newObj);
    }
  };

  useEffect(() => {
    reverseArray(serviceData);
    console.log(isMobile);
  }, [isMobile]);

  return (
    <section
      className="flat-row pd-services-post"
      style={{ padding: "5px", backgroundColor: "#2C394B", color: "white" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            {data.titleServices.map((data) => (
              <div
                className="title-section center s1"
                key={data.id}
                style={{ marginTop: "20px" }}
              >
                <h3>{data.title} </h3>
                <p className="sub-title-section">{data.description} </p>
              </div>
            ))}
            <div className="dividers dividers-imagebox"></div>
          </div>
        </div>
        <div className="row">
          <div
            className="col-md-12"
            // style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            <div
              className="wrap-imagebox-grid"
              // style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
            >
              {data.imagebox.map((data) => (
                <div
                  className="flat-imagebox services-grid item"
                  id="outerBox"
                  key={data.id}
                >
                  <div className="flat-imagebox-inner" id="serviceBox">
                    <div className="flat-imagebox-image">
                      <img
                        src={data.imgsrc}
                        alt="img"
                        style={{
                          padding: "0px",
                          height: "300px",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                    <div
                      id="content"
                      style={{ padding: "10px", height: "400px" }}
                    >
                      <div className="flat-imagebox-header">
                        <h3
                          className="flat-imagebox-title"
                          style={{
                            fontFamily: "Open Sans",
                            color: "white",
                          }}
                        >
                          {data.title}
                        </h3>
                        <hr />
                      </div>
                      <div className="flat-imagebox-content">
                        <div className="flat-imagebox-desc">
                          <p
                            style={{
                              textAlign: "start",
                              fontSize: "17px",
                              fontFamily: "Open Sans",
                            }}
                          >
                            {data.description}
                          </p>
                        </div>
                        <div className="flat-imagebox-button"></div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
