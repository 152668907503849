import React from "react";
import "./header.css";
import {
  BrowserRouter as Router,
  Switch,
  Routes,
  Route,
  Link,
} from "react-router-dom";

import LanGswitch from "../../components/language-switcher/languageswitcher";
import { useTranslation } from "react-i18next";
import "../../translations/i118";

const Header = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="main-header" style={{ direction: "ltr" }}>
        <div className="left">
          <div>
            <img className="logoimg" src="assets/logo-bg.jpg" alt="logo" />
          </div>
          <div className="brandName">
            <p style={{ fontSize: "30px", fontFamily: "Noto Kufi Arabic" }}>
              واحة البادية الخضراء للتجارة
            </p>
            <p
              style={{
                fontSize: "25px",
                fontFamily: "Exo",
                fontWeight: "bold",
                marginTop: "-20px",
              }}
            >
              AL BADIA GREEN OASIS TRADING
            </p>
          </div>
        </div>
        <div className="right">
          <ul className="navbar02">
            <li>
              {" "}
              <Link to="/" className="nav02">
                {t("home_button")}
              </Link>
            </li>
            <li>
              {" "}
              <Link to="aboutus" className="nav02">
                {t("about_us_button")}
              </Link>
            </li>
            <li>
              <Link to="projects" className="nav02">
                {t("gallary_button")}
              </Link>
            </li>
            <li>
              <Link to="services" className="nav02">
                {t("services_button")}
              </Link>
            </li>
            <li>
              <Link to="contacts" className="nav02">
                {t("contact_button")}
              </Link>
            </li>

            <li>
              <Link to="cirtificates" className="nav02">
                {t("certificate_button")}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Header;
