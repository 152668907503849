export const TRANSLATIONS_EN = {
  welcome_to_home: "Welcome to",
  company_name: "AL BADIA GREEN OASIS TRADING",
  tagline: "We Do Truck Rental and Construction Services",

  home_button: "Home",
  about_us_button: "About us",
  services_button: "Services",
  gallary_button: "Gallary",
  contact_button: "Contact",
  certificate_button: "Certificates",

  about_us: "Who We are ",
  profile_home:
    "AL BADIA GREEN OASIS TRADING  has been established as Trucking of Goods and Equipment Company in Sultanate of Oman to provide , Land transport , Rental of equipments ,Import and supply of goods and Civil construction work.   AL BADIA GREEN OASIS TRADING has registered as an approved supplier to the Oman Oil & Gas Industry’s Joint Supplier Registration System (JSRS).Today, AL BADIA GREEN OASIS TRADING  can fulfill the transportation needs of prestigious clients all-across Sultanate of Oman. Beside our Transportation services we also do construction works and take on the role of main contractor to small and medium sized projects.  ",
  vision: "Vision",
  mission: "Mission",
  mission_text:
    "To build long term relationships with our customers and clients and provide exceptional customer services by pursuing business through innovation and advanced technology.",
  vision_text:
    "To provide quality services that exceeds the expectations of our esteemed customers.",
  // home_page_chairmans_section

  message_from_chairman: "Message From Chairman",
  message_description:
    "Our objective is to provide our clients with an “I am assured” experience when we are chosen to execute their projects. Our emphasis on clear communication and follow-through procedures ensures that client’s objectives are top priority in the planning and execution of all our processes",
  shaike_name: "Muhammad Mabkhout said Jadad",
  shaike_designation: "General Manager",
  //home_page_services_section

  our_services: "Our Services",

  services_heading: "Our Services",
  construction_home: "Construction",

  construction_description: `AL BADIA GREEN OASIS TRADING EST. can take on the role of
  main contractor for small to medium size projects and performs project
  management services. We also can provide engineering solutions as
  value-add services to our clients.`,

  transport_of_goods: "Land Transportation",
  transport_description: `AL BADIA GREEN OASIS TRADING can fulfill the Land transportation needs of our valued customers with prompt quality. We are able to  supply wide range of heavy vehicles  like Tipper Truck ,Skip Loader Truck with Skip Boxes,Potable Water Tanker (8000 Gallons),Sewage Water Tanker (8000 Gallons) in Rental basis.
  Our Transportation Service includes. \n 
  1.Soil, Sands & Rocks Transportation
  2.Fresh Water Transportation
  3.Recyclable non-drinking Water Transportation
  4.Wast & Sewage Transportation .`,
  water_treatment_plant: "Water Treatment Plant",
  water_description: `We have recently established a water treatment plant as an
  additional service to provide drinking water for our valued Customers.
  The plant is aimed to treat water pumped from local boreholes.`,

  //home_page_Health&Safty

  health_safty_policy_home: "Health and Safty Policy",
  health_safty_description:
    "AL BADIA GREEN OASIS TRADING Management is firmly committed to a policy enabling all work activities to be carried out safely, and with all possible measures taken to eliminate risks to the health, safety and welfare of workers, contractors,authorised visitors, and anyone else who may be affected by our operations.",
  health_safty_description2:
    "We seek the co-operation of all workers, customers and other persons. We encourage suggestions for realising our health and safety objectives to create a safe working environment with a zero accident rate. This policy applies to all business operations and functions, including those situations where workers are required to work off-site.",

  //Awards & Achivement

  award_divider: "Awards & Achievement",
  JSRS: "JSRS cirtificate of Approval",

  //contact page

  address: "Buissness Address",
  address_text: `Thumrait – Dhofar Governorate,\n P.O. Box: 22 \nPostal Code: 222,\n
    Sultanate of Oman`,
  phone: "Phone Number",
  email: "E-mail Address",

  //About us page

  history_philosophy: "Overview",
  history01:
    "AL BADIA GREEN OASIS TRADING EST. began as a general works Subcontractor in 2017. Since then, the company has undertaken many challenging projects and accumulated skills, know-how and experiences in civil works construction, project management services and related engineering works. Today, AL BADIA GREEN OASIS TRADING EST. can take on the role of main contractor for small to medium size projects and performs project management services. We also can provide engineering solutions as value-add services to our clients.",
  history02:
    "Our objective is to provide our clients with an “I am assured” experience when we are chosen to execute their projects. Our emphasis on clear communication and follow-through procedures ensures that client’s objectives are top priority in the planning and execution of all our processes.",

  company_startagy: "Company strategy",
  purpose: "Purpose",
  purpous_text:
    "To be a leader in the Civil Construction industry by providing enhanced services, relationship and profitability.",
  core_values: "Core values",
  core_values_text:
    "We believe in treating our customers with respect and faith, We grow through creativity, invention and innovation, We integrate honesty, integrity and business ethics into all aspects of our business functioning",
  goals: "Goals",
  goals_text:
    "Regional expansion in the field of Civil Construction and develop a strong base of key customers, Increase the assets and investments of the company to support the development of services, To build good reputation in the field of Construction Works and management and become a key player in the industry.",

  scopeOfWork: `Our scope of work includes  Land Transportation , Import and supply of goods, Rental of Equipment and Construction. AL BADIA GREEN OASIS TRADING can fulfill the Land transportation needs of our valued customers with prompt quality. We are able to supply wide range of heavy vehicles like Tipper Truck ,Skip Loader Truck with Skip Boxes,Potable Water Tanker (8000 Gallons),Sewage Water Tanker (8000 Gallons) in Rental basis. Our Transportation Service includes. 1.Soil, Sands & Rocks Transportation 2.Fresh Water Transportation 3.Recyclable non-drinking Water Transportation 4.Wast & Sewage Transportation .`,
  work: " Scope of Work",
  //equipment table
  equipment: "Trucks Available for Rent",
  dozer: "Dozer",
  shoval: `Shovel`,
  grader: `Grader`,
  skippTruck: `Skip Truck`,
  tipperTruck: `Tipper Truck`,
  waterTanker1: `
  Water Tanker 8000 gallon(blue)`,
  waterTanker2: `
  Water Tanker 8000 gallon(green)`,
  waterTanker3: `Sewage Tanker 8000 gallon(yellow)`,
  waterTreatmentPlant: `Water Treatment Plant, 70,000 gallon/day`,
  nameofEquipmwnt: `Name of Equipment and Vahicle`,
  num: `Nos`,

  //footer

  logoTag: `AL BADIA GREEN OASIS TRADING conducts civil construction works as well as rental of equipment and as a civil subcontractor to the Oman Oil & Gas Industries`,

  addressLine1: "Thumrait – Dhofar Governorate",
  line2: `P.O.Box: 22`,
  line3: `Postal
   Code: 222`,
  line4: `Sultanate of Oman`,
  HeadOffice: "HEAD OFFICE",
  contact01: `CONTACT`,

  // speacial buttons
  view_more: `View More`,
  read_more: `Read More`,
};
