import HeroSlider, { Overlay, Slide, MenuNav } from "hero-slider";
import Wrapper from "../Wrapper";
import Title from "../Title";
import Subtitle from "../Subtitle";
import "./hero-slider.css";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";
import "../../translations/i118";

const truckRow = "assets/truck/truck-row1.jpeg";
const construction1 = "assets/stock-img/img6.jpg";
const truckrow1 = "https://owwsc.nama.om/WDocument/yellow-tanker";
const greenTanker =
  "https://live.staticflickr.com/65535/49957384322_30eee71fda_b.jpg";

const truck = "assets/stock-img/img5.jpg";

const Company_name_style = {
  fontSize: "50px",
  fontWeight: "bold",
  fontFamily: "Open Sans",
  padding: "10px",
  marginTop: "25px",
  marginBottom: "45px",
  lineheight: "145px",
  backgroundColor: "rgba(255, 255, 255, 0.25)",
};

const Welcome_style = {
  fontSize: "45px",
  marginBottom: "25px",
};

export default function BasicSlider() {
  const { t } = useTranslation();

  return (
    <div className="Hero_desktop">
      <HeroSlider
        slidingAnimation="top_to_bottom"
        orientation="horizontal"
        height={"75vh"}
        autoplay
        controller={{
          initialSlide: 1,
          slidingDuration: 300,
          slidingDelay: 0,
          onSliding: (nextSlide) =>
            console.debug("onSliding(nextSlide): ", nextSlide),
          onBeforeSliding: (previousSlide, nextSlide) =>
            console.debug(
              "onBeforeSliding(previousSlide, nextSlide): ",
              previousSlide,
              nextSlide
            ),
          onAfterSliding: (nextSlide) =>
            console.debug("onAfterSliding(nextSlide): ", nextSlide),
        }}
        style={{
          backgroundColor: "#000",
        }}
        settings={{
          slidingDuration: 500,
          slidingDelay: 100,
          shouldAutoplay: true,
          shouldDisplayButtons: false,
          autoplayDuration: 5000,
          height: "90vmin",
        }}
      >
        <Overlay>
          <Wrapper>
            <Title>
              <span style={Welcome_style}> {t("welcome_to_home")} </span>
              <br />
              {"         "}
              <span style={Company_name_style}> {t("company_name")}</span>
            </Title>
            {/* <Subtitle>{t("tagline")}</Subtitle> */}

            <p
              style={{
                width: "35%",
                height: "",
                fontFamily: "Open Sans",
                backgroundColor: "rgba(5, 8, 10, 0.7)",
                fontSize: "25px",
                padding: "10px",
              }}
            >
              {t("tagline")}
            </p>
            <Link to="/contacts">
              <button className="button-6">Contact Us for a free quote</button>
            </Link>
          </Wrapper>
        </Overlay>

        <Slide
          // shouldRenderMask
          label="Transport"
          background={{
            backgroundImageSrc: construction1,
            // backgroundAnimation: "zoom",
          }}
        />

        <Slide
          label="Construction"
          background={{
            backgroundImageSrc: truckRow,
            backgroundAnimation: "zoom",
            backgroundBlendMode: "luminosity",
            maskBackgroundBlendMode: "luminosity",
            backgroundImage: truckRow,
          }}
        />

        {/* <Slide
          shouldRenderMask
          label="Sweage Treatment"
          background={{
            backgroundImageSrc: truckrow1,
          }}
        /> */}

        <Slide
          // shouldRenderMask
          label="4"
          background={{
            backgroundImageSrc: truck,
            backgroundAnimation: "zoom",
          }}
        />
        <Slide
          // shouldRenderMask
          label="5"
          background={{
            backgroundImageSrc: greenTanker,
            backgroundAnimation: "zoom",
          }}
        />
      </HeroSlider>
    </div>
  );
}

// import React from "react";
// // JSX
// import HeroSlider, { Slide, ButtonsNav, Nav, Overlay } from "hero-slider";
// // import Wrapper from "../UI/Wrapper/Wrapper";
// // import Title from "../UI/Title/Title";
// // import Subtitle from "../UI/Subtitle/Subtitle";

// // Images
// const hallstatt = "assets/truck/truck-row1.jpeg";
// const hvitserkur = "assets/truck/truck-row1.jpeg";
// const jacksonville = "assets/truck/truck-row1.jpeg";
// const moraineLake = "assets/truck/truck-row1.jpeg";

// const app = () => {
//   return (
//     <HeroSlider
//       orientation="horizontal"
//       initialSlide={1}
//       onBeforeChange={(previousSlide, nextSlide) =>
//         console.log("onBeforeChange", previousSlide, nextSlide)
//       }
//       onChange={(nextSlide) => console.log("onChange", nextSlide)}
//       onAfterChange={(nextSlide) => console.log("onAfterChange", nextSlide)}
//       style={{
//         backgroundColor: "#000",
//       }}
//       settings={{
//         slidingDuration: 500,
//         slidingDelay: 100,
//         shouldAutoplay: true,
//         shouldDisplayButtons: true,
//         autoplayDuration: 5000,
//         height: "100vh",
//       }}
//     >
//       {/* <OverlayContainer>
//         <Wrapper>
//           <Title>Zoom Slider</Title>
//           <Subtitle>
//             Slides' backgroundAnimation prop set to 'zoom' (you may reload the
//             page if it's already over)
//           </Subtitle>
//         </Wrapper>
//       </OverlayContainer> */}

//       <Slide
//         navDescription="Hallstatt - Austria"
//         background={{
//           backgroundImage: hallstatt,
//           backgroundAnimation: "zoom",
//         }}
//       />

//       <Slide
//         navDescription="Hvitserkur - Iceland"
//         background={{
//           backgroundImage: hvitserkur,
//           backgroundAnimation: "zoom",
//         }}
//       />

//       <Slide
//         navDescription="Jacksonville - USA"
//         background={{
//           backgroundImage: jacksonville,
//           backgroundAnimation: "zoom",
//         }}
//       />

//       <Slide
//         navDescription="Moraine Lake - Canada"
//         background={{
//           backgroundImage: moraineLake,
//           backgroundAnimation: "zoom",
//         }}
//       />

//       <ButtonsNav
//         isNullAfterThreshold
//         position={{
//           top: 0,
//           left: "50%",
//           transform: "translateX(-50%)",
//         }}
//       />
//       <Nav />
//     </HeroSlider>
//   );
// };

// export default app;
