import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import i18n from "../../../translations/i118";
export default function ColorToggleButton() {
  const [alignment, setAlignment] = React.useState("web");

  const [language, setLanguage] = React.useState("en");

  const handleChange = (e) => {
    e.preventDefault();
    setLanguage(e.target.value);
    console.log(e.target.value);
    i18n.changeLanguage(e.target.value);
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={alignment}
      onChange={handleChange}
      aria-label="Platform"
      sx={{ backgroundColor: "white", height: "25px", marginRight: "-450px" }}
      size="small"
    >
      <ToggleButton
        value="ar"
        size="small"
        sx={{
          height: "25px",
          fontFamily: "Noto Kufi Arabic",
          fontSize: "17px",
        }}
      >
        عربى
      </ToggleButton>
      <ToggleButton value="en" size="small" sx={{ height: "25px" }}>
        English
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
