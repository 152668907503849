import React from "react";
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-react-ui-kit";
import { TfiLocationPin } from "react-icons/tfi";
import { Divider } from "antd";
import { Link } from "react-router-dom";
// import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import LanguageSelector from "../../components/language-switcher/languageswitcher";
import { useTranslation } from "react-i18next";
import "../../translations/i118";

export default function App() {
  const { t } = useTranslation();
  const global_styles = {
    textAlign: "start",
  };
  const iconStyle = {
    display: "flex",
    alignItems: "space-between",
    justifyContent: "space-between",
    fontSize: "17px",
    marginTop: "50px",
    // gap: "8px",
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <MDBFooter
      bgColor="light"
      className="text-center text-lg-start text-muted"
      style={global_styles}
    >
      <section className="d-flex justify-content-center justify-content-lg-between p-4 ">
        <div className="me-5 d-none d-lg-block"></div>
      </section>

      <section className="">
        <MDBContainer
          className="text-center text-md-start mt-5"
          style={global_styles}
        >
          <MDBRow className="mt-3">
            <MDBCol
              md="3"
              lg="4"
              xl="3"
              className="mx-auto mb-4"
              style={global_styles}
            >
              <img
                src="assets/logo-bg.jpg"
                alt="logo"
                style={{ width: "230px", height: "120px" }}
              />
              <h4 style={{ marginTop: "5px", fontFamily: "Noto Kufi Arabic" }}>
                {" "}
                {t("company_name")}
              </h4>
              <p>{t("logoTag")}</p>
              <p>© 2017 , All rights reserved</p>
            </MDBCol>

            <MDBCol
              md="2"
              lg="2"
              xl="2"
              className="mx-auto mb-4"
              style={global_styles}
            >
              <h6 className="text-uppercase fw-bold mb-4">Links</h6>
              <p>
                <Link to="/" onClick={() => scrollToTop()}>
                  {t("home_button")}
                </Link>
              </p>
              <p>
                <Link to="aboutus" onClick={() => scrollToTop()}>
                  {t("about_us_button")}
                </Link>
              </p>
              <p>
                <Link to="/projects" onClick={() => scrollToTop()}>
                  {t("gallary_button")}
                </Link>
              </p>
              <p>
                <Link to="services" onClick={() => scrollToTop()}>
                  {t("services_button")}
                </Link>
              </p>
              <p>
                <Link to="/contacts" onClick={() => scrollToTop()}>
                  {t("contact_button")}
                </Link>
              </p>
            </MDBCol>

            <MDBCol
              md="3"
              lg="2"
              xl="2"
              className="mx-auto mb-4"
              style={global_styles}
            >
              <h6 className="text-uppercase fw-bold mb-4">{t("HeadOffice")}</h6>

              <p style={{ textAlign: "start", fontSize: "18px" }}>
                {t("addressLine1")} <br /> {t("line2")} <br /> {t("line3")}
                <br /> {t("line4")}
              </p>
            </MDBCol>

            <MDBCol
              md="4"
              lg="3"
              xl="3"
              className="mx-auto mb-md-0 mb-4"
              style={global_styles}
            >
              <h6 className="text-uppercase fw-bold mb-4">{t("contact01")}</h6>
              <p>
                <MDBIcon color="secondary" icon="home" className="me-2" />
                {t("address_text")}
              </p>
              <p>
                <MDBIcon color="secondary" icon="envelope" className="me-3" />
                <a
                  href="mailto:oman91160006@gmail.com"
                  style={{ color: "gray", curser: "pointer" }}
                >
                  Email: albadiagreen@gmail.com
                </a>
              </p>
              <p>
                <MDBIcon color="secondary" icon="phone" className="me-3" />
                91160006
              </p>

              <div style={iconStyle}>
                <a href="" className="me-4 text-reset">
                  <MDBIcon size="lg" color="secondary" fab icon="facebook-f" />
                </a>
                <a href="" className="me-4 text-reset">
                  <MDBIcon color="secondary" fab icon="twitter" />
                </a>
                <a href="" className="me-4 text-reset">
                  <MDBIcon size="lg" color="secondary" fab icon="google" />
                </a>
                <a href="" className="me-4 text-reset">
                  <MDBIcon size="lg" color="secondary" fab icon="instagram" />
                </a>
                <a href="" className="me-4 text-reset">
                  <MDBIcon size="lg" color="secondary" fab icon="whatsapp" />
                </a>
                {/* <a href="" className="me-4 text-reset">
            <MDBIcon color="secondary" fab icon="github" />
          </a> */}
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div
        className="text-center p-4"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
      >
        <LanguageSelector />© 2017 Copyright:
        <p className="text-reset fw-bold" href="https://mdbootstrap.com/">
          AL BADIA GREEN OASIS TRADING EST.
        </p>
      </div>
    </MDBFooter>
  );
}
