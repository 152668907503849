import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { TRANSLATIONS_ARABIC } from "./arabic/ar";
import { TRANSLATIONS_EN } from "./english/en";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: TRANSLATIONS_EN,
      },
      ar: {
        translation: TRANSLATIONS_ARABIC,
      },
    },
  });

i18n.changeLanguage("en");

export default i18n;
