import React from "react";

import Header from "../../components/header/header";
import Slider from "../../components/hero-slider/hero-slider";
import Profile from "../../components/profile/profile";
import Buissnessactivities from "../../components/services-card/buissnessactivities";
import Carosal from "../../components/carosal-client/carosal";
import Safty from "../../components/safty-policy/safty-policy";
import ChairmansMessage from "../../components/chairmans-message/chairmans-message";
import Cirtificates from "../../components/cirtificates/cirtificates";

//from finances

import Services from "../../components/service-new/Services";
import Chairmans from "../../components/chairmans-message/chairman";
import Slider2 from "../../components/slider-fn/slider";

//from service page

import Services01 from "../OurServices/services";

import Navbar from "../../components/mdb-nav-icon/mdb-icon";
import WhatsApp from "../../components/react-whatsapp/whatsApp";
//Mobile View

import HeroSliderMobile from "../../components/mobileview/hero-slider/hero-slider";
import ProfileMobile from "../../components/mobileview/profile/profile";
import ChairmansMessageMobile from "../../components/mobileview/message-from-chairman/chairmansMessage";
const Home = () => {
  return (
    <>
      <div>
        {/* <Navbar /> */}

        <Slider />
        {/* mobileview */}
        <HeroSliderMobile />
        <Profile />
        <ProfileMobile />
        <Services01 />
        <ChairmansMessage />
        <ChairmansMessageMobile />

        <Safty />
        <Cirtificates />
        <Carosal />
      </div>
    </>
  );
};

export default Home;
