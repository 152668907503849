import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { Header, TopBar , Footer, Loader } from '../layouts/general';
import MainServices from "./mainServices";
import { Divider } from "antd";
import { useTranslation } from "react-i18next";
import "../../translations/i118";

const Services = () => {
  const { t } = useTranslation();

  const data = {
    headers: [
      {
        id: 1,
        names: "Pages",
      },
    ],
    titleheading: [
      {
        id: "1",
        title: "Services Grid",
      },
    ],
    breadcrumbs: [
      {
        id: 1,
        title: "Home",
        classicon: "fa fa-angle-right",
        aria: "true",
      },
      {
        id: 2,
        title: "Services",
        classicon: "fa fa-angle-right",
        aria: "true",
      },
      {
        id: 3,
        title: "Services Grid",
        classicon: "",
        aria: "",
      },
    ],
  };

  return (
    <>
      {/* <Divider orientation="left" style={{ marginTop: "50px" }}>
        <h2
          style={{
            fontWeight: "normal",
            color: "#393E46",
            fontFamily: "Montserrat",
            fontSize: "25px",
          }}
        >
          {t("our_services")}
        </h2>
      </Divider> */}
      <div className="bg-body3">
        <div className="boxed">
          {/* <div className="page-title">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="page-title-heading">
                    <h1 className="h1-title">Services Grid</h1>
                  </div>
                  <ul className="breadcrumbs">
                    {this.state.breadcrumbs.map((data) => (
                      <li key={data.id}>
                        <Link to="#" title="">
                          {data.title}
                          <i
                            className={data.classicon}
                            aria-hidden={data.aria}
                          ></i>
                        </Link>
                      </li>
                    ))}
                  </ul>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
          </div> */}

          <MainServices />
        </div>
      </div>
    </>
  );
};

export default Services;
