import React from "react";
// import Header from "../../components/header/header";
import TimeLine from "../../components/timeline/timeline";
import { Col, Divider, Row } from "antd";
import { Timeline } from "antd";
import { SmileOutlined } from "@ant-design/icons";
import Table from "../../components/equipment-table/table";
import "./aboutus.css";
import MobileView from "../../components/mobileview/aboutUs/aboutus";
import { useTranslation } from "react-i18next";
import "../../translations/i118";
import { MDBIcon, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { Card } from "antd";
import SimpleCard from "../../components/simpleCard/card";
import CarosalAboutus from "../../components/carosal-slides/carosal";

import { Layout } from "antd";
const { Header, Footer, Sider, Content } = Layout;

const style1 = {
  background: "#FFFFFF",

  padding: "30px",
  boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
  height: "400px",
};
const style2 = {
  background: "#FFFFFF",
  padding: "25px",
  height: "250px",
  boxShadow:
    "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
};

const paragraph_style = {
  padding: "45px",
  textAlign: "start",
  fontSize: "19px",
  color: "#3C4048",
  fontFamily: "Helvetica",
};

const strategy_header_style = {
  fontSize: "20px",
  fontWeight: "bold",
};
const stratagyHeader = {
  fontSize: "26px",

  color: "#2B4865",
};

const Aboutus = () => {
  const { t } = useTranslation();

  const strategy_data = [
    { icon: "pen-nib", title: t("purpose"), description: t("purpous_text") },
    { icon: "eye", title: t("vision"), description: t("vision_text") },
    { icon: "bullseye", title: t("mission"), description: t("mission_text") },
    {
      icon: "heart",
      title: t("core_values"),
      description: t("core_values_text"),
    },
  ];

  return (
    <>
      <div className="desktop-view">
        {/* <Sider style={{ backgroundColor: "#59CE8F" }}></Sider> */}
        <Content style={{ padding: "80px" }}>
          {" "}
          <Divider orientation="left">
            <h2 style={stratagyHeader}>{t("history_philosophy")}</h2>
          </Divider>
          <Row gutter={16}>
            <Col className="gutter-row" span={12}>
              <div style={style1}>
                {" "}
                <p style={paragraph_style}>
                  {t("profile_home")}
                  <br />
                  {/* {t("history02")} */}
                </p>
              </div>
            </Col>
            <Col className="gutter-row" span={12}>
              <div style={style1}>
                <CarosalAboutus />
                {/* <h2>Our project management and execution philosophy is:</h2>
                    <Timeline>
                      <Timeline.Item>
                        <p style={{ textAlign: "start", fontSize: "15px" }}>
                          {" "}
                          Create detail schedule and resources plan to meet
                          client’s project objective,
                        </p>{" "}
                      </Timeline.Item>
                      <Timeline.Item>
                        <p style={{ textAlign: "start", fontSize: "15px" }}>
                          {" "}
                          Communicate clearly with all project stakeholders,
                        </p>{" "}
                      </Timeline.Item>

                      <Timeline.Item>
                        <p style={{ textAlign: "start", fontSize: "15px" }}>
                          {" "}
                          Track project progress and fine-tune deviations
                        </p>{" "}
                      </Timeline.Item>
                      <Timeline.Item>
                        <p style={{ textAlign: "start", fontSize: "15px" }}>
                          {" "}
                          Supervise closely on quality of work done
                        </p>{" "}
                      </Timeline.Item>
                      <Timeline.Item Item color="#59CE8F">
                        <p style={{ textAlign: "start", fontSize: "15px" }}>
                          {" "}
                          Complete and commission the project on time.
                        </p>{" "}
                      </Timeline.Item>
                    </Timeline> */}
              </div>
            </Col>
          </Row>
          <Divider orientation="left" style={{ marginTop: "80px" }}>
            {" "}
            <h2 style={stratagyHeader}>{t("company_startagy")}</h2>
          </Divider>
          <MDBRow className="row-cols-1 row-cols-md-2 g-4">
            {strategy_data.map((item, index) => {
              return (
                <MDBCol sm="6">
                  <SimpleCard
                    icon={item.icon}
                    title={item.title}
                    text={item.description}
                  />
                </MDBCol>
              );
            })}
          </MDBRow>
          <Divider orientation="left" style={{ marginTop: "50px" }}>
            {" "}
            <h2 style={stratagyHeader}>{t("work")}</h2>
          </Divider>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={12}>
              <div style={style1}>
                {/* <h3>Purpose:</h3> */}
                <p style={{ textAlign: "start", fontSize: "19px" }}>
                  {t(`scopeOfWork`)}
                  <br />
                </p>
              </div>
            </Col>
            <Col className="gutter-row" span={12}>
              <div style={style1}>
                <img
                  src="https://media.istockphoto.com/id/688591746/photo/dumptruck-in-action.jpg?s=612x612&w=0&k=20&c=pyQNwmDm4UNzT9rvYMPwCLk4NP-7_K6rmo5SZycuF6Q="
                  style={{
                    padding: "10px",
                    width: "100%",
                    height: "350px",
                    objectFit: "cover",
                  }}
                />
              </div>
            </Col>
          </Row>
          <Divider orientation="left" style={{ marginTop: "80px" }}>
            {" "}
            <h2 style={stratagyHeader}>{t("equipment")}</h2>
          </Divider>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            {/* <Col className="gutter-row" span={24}> */}
            {/* <h3>Purpose:</h3> */}

            <Table />
            {/* </Col> */}
          </Row>
          <Divider orientation="left" style={{ marginTop: "80px" }}>
            {" "}
            <h2 style={stratagyHeader}> {t("health_safty_policy_home")}</h2>
          </Divider>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={12}>
              {/* <h3>Purpose:</h3> */}
              <div style={style1}>
                <p style={{ textAlign: "start", fontSize: "19px" }}>
                  {t("health_safty_description")}
                </p>

                <p style={{ textAlign: "start", fontSize: "19px" }}>
                  {t("health_safty_description2")}
                </p>
              </div>
            </Col>

            <Col className="gutter-row" span={12}>
              <div style={style1}>
                <img
                  src="https://media.istockphoto.com/id/521261573/photo/workplace-safety-handbook-manual-and-occupational-equipment-for-work-training.jpg?s=612x612&w=0&k=20&c=KG_D5v8xIfnR0jje69nQqFQYmerYBiquQrXqFLkjPHA="
                  style={{
                    padding: "10px",
                    height: "350px",
                    objectFit: "cover",
                    borderRadius: "7px",
                    border: "1px solid",
                  }}
                />
              </div>
            </Col>
          </Row>
        </Content>
      </div>
      <MobileView />
    </>
  );
};

export default Aboutus;
