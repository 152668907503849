// import React from "react";

// import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
// import { Divider } from "antd";
// import { Button } from "antd";

// import LightGallery from "lightgallery/react";

// import "lightgallery/css/lightgallery.css";
// import "lightgallery/css/lg-zoom.css";
// import "lightgallery/css/lg-thumbnail.css";

// import lgThumbnail from "lightgallery/plugins/thumbnail";
// import lgZoom from "lightgallery/plugins/zoom";
// // import LightGallerySettings from "lightgallery/lg-settings";
// import lgVideo from "lightgallery/plugins/video";

// const cirfificatesMain = () => {
//   return (
//     <>
//       <div
//         style={{ marginTop: "50px", marginBottom: "50px", direction: "rtl" }}
//       >
//         {/* <MDBContainer>
//           <MDBRow> */}
//         <div>
//           <LightGallery
//             // onInit={onInit}
//             speed={500}
//             plugins={[lgThumbnail, lgZoom, lgVideo]}
//             mode="lg-fade"
//             controls="true"
//             height="400px"
//             // elementClassNames="custom-wrapper-class"
//             mousewheel={true}
//           >
//             <a href="assets/cirtificates/jrs.png">
//               <img
//                 alt="assets/cirtificates/jrs.png"
//                 src="assets/cirtificates/jrs.png"
//                 style={{
//                   height: "400px",
//                   border: "5px solid #EFF5F5",
//                   borderRadius: "5px",
//                 }}
//               />
//             </a>
//             <a href="assets/cirtificates/ale.png">
//               <img
//                 alt="img2"
//                 src="assets/cirtificates/ale.png"
//                 style={{
//                   height: "400px",
//                   border: "5px solid #EFF5F5",
//                   borderRadius: "5px",
//                 }}
//               />
//             </a>
//             <a href="assets/cirtificates/pdo.jpeg">
//               <img
//                 alt="img2"
//                 src="assets/cirtificates/pdo.jpeg"
//                 style={{
//                   height: "400px",
//                   border: "5px solid #EFF5F5",
//                   borderRadius: "5px",
//                 }}
//               />
//             </a>
//           </LightGallery>
//         </div>
//         {/* </MDBRow>
//         </MDBContainer> */}
//       </div>
//     </>
//   );
// };

// export default cirfificatesMain;

import React from "react";
import { Image } from "antd";

const imageStyle = {
  width: "400px",
  height: "300px",
  Objectfit: "cover",
  padding: "5px",
};

const App = () => (
  <div style={{ marginTop: "100px", marginBottom: "100px" }}>
    <Image.PreviewGroup>
      <Image style={imageStyle} src="assets/cirtificates/jrs.png" />
      <Image style={imageStyle} src="assets/cirtificates/ale.png" />
    </Image.PreviewGroup>
  </div>
);

export default App;
