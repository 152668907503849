import { Col, Row } from "antd";
import { Divider, Button, Card } from "antd";
import "./profile.css";
import { Link } from "react-router-dom";
import { GrWorkshop } from "react-icons/gr";
import { WiSmallCraftAdvisory } from "react-icons/wi";
import Table from "../../components/equipment-table/table";
import { MdEngineering } from "react-icons/md";
import { ImEye } from "react-icons/im";
import { GiStairsGoal } from "react-icons/gi";
import {
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBTypography,
  MDBCardTitle,
} from "mdb-react-ui-kit";

import { useTranslation } from "react-i18next";
import "../../translations/i118";

const Profile = () => {
  const { t } = useTranslation();

  return (
    <>
      <div
        style={{
          backgroundColor: "#1A120B",
          padding: "20px",
          marginTop: "0px",
          marginBottom: "0px",
        }}
        className="main-content"
      >
        <Row>
          <Col
            span={12}
            gutter={4}
            style={{ padding: "20px", marginTop: "0px" }}
          >
            <Card hoverable>
              <Row>
                {" "}
                <h2 style={{ fontFamily: "Open Sans" }}>{t("about_us")}</h2>
              </Row>
              <Row>
                <p
                  style={{
                    textAlign: "start",
                    fontSize: "20px",
                    fontFamily: "Open Sans",
                  }}
                >
                  {t("profile_home")}
                </p>{" "}
                <Link
                  // onClick={() => {
                  //   window.location.href = "/aboutus";
                  // }}
                  to="/aboutus"
                >
                  {" "}
                  <Button className="lern-more"> {t("read_more")} </Button>{" "}
                </Link>
              </Row>
            </Card>
          </Col>
          <Col span={12} gutter={4} style={{ padding: "20px" }}>
            <Row>
              {/* <Col gutter={2} style={{ padding: "10px" }}>
                <Row>
                  {" "}
                  <MdEngineering
                    style={{
                      fontSize: "40px",
                      marginRight: "10px",
                      color: "#59CE8F",
                      marginLeft: "10px",
                    }}
                  />
                  <h3 style={{ fontFamily: "Open Sans" }}>{t("mission")}</h3>
                </Row>
              </Col>
              <Col gutter={2} style={{ padding: "10px" }}>
                <p
                  style={{
                    textAlign: "start",
                    fontSize: "20px",
                    fontFamily: "Open Sans",
                  }}
                >
                  {t("mission_text")}
                </p>
              </Col> */}

              <MDBCard
                style={{
                  backgroundColor: "#434242",
                  color: "white",
                  width: "100%",
                }}
              >
                <MDBCardBody>
                  <div style={{ display: "flex", flexDirection: "reow" }}>
                    <img
                      width="75"
                      height="75"
                      alt="Transatlantic Infraprojects Pvt. Ltd"
                      // data-src="https://atlanticoman.com/wp-content/uploads/2020/08/icon3.png"
                      // class="elementor-animation-grow attachment-full size-full wp-image-97 ls-is-cached lazyloaded"
                      src="https://atlanticoman.com/wp-content/uploads/2020/08/icon3.png"
                    ></img>
                    <p style={{ fontSize: "25px", padding: "10px" }}>
                      {t("mission")}
                    </p>
                  </div>

                  <MDBTypography blockquote className="mb-0">
                    <p>{t("mission_text")}</p>
                  </MDBTypography>
                </MDBCardBody>
              </MDBCard>
              <MDBCard
                style={{
                  backgroundColor: "#434242",
                  color: "white",
                  marginTop: "20px",
                  width: "100%",
                }}
              >
                <MDBCardBody>
                  <div style={{ display: "flex", flexDirection: "reow" }}>
                    {" "}
                    <img
                      width="75"
                      height="75"
                      src="https://atlanticoman.com/wp-content/uploads/2020/08/icon1.png"
                    ></img>
                    <p style={{ fontSize: "25px", padding: "10px" }}>
                      {t("vision")}
                    </p>
                  </div>

                  <MDBTypography blockquote className="mb-0">
                    <p>{t("vision_text")}</p>
                  </MDBTypography>
                </MDBCardBody>
              </MDBCard>
            </Row>
            <div>
              {/* <Row gutter={2} style={{ padding: "10px" }} span={12}>
                <Row span={12}>
                  {" "}
                  <GiStairsGoal
                    style={{
                      fontSize: "40px",
                      marginRight: "10px",
                      color: "#59CE8F",
                      marginLeft: "10px",
                    }}
                  />
                  <h3 style={{ fontFamily: "Open Sans" }}>{t("vision")}</h3>
                </Row>
              </Row> */}
              {/* <Col gutter={2} style={{ padding: "10px" }}>
                <p
                  style={{
                    textAlign: "start",
                    fontSize: "20px",
                    fontFamily: "Open Sans",
                  }}
                >
                  {t("vision_text")}
                </p>
              </Col> */}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Profile;
