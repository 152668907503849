import React from "react";
import GallaryTab from "../../components/gallary-tab/gallary";
import Gallary from "../../components/mdb-tab-gallary/mdb-tab-gallary";
import TruckGallary from "../../components/TruckGallary/truckgallary";
import ResposiveCarosal from "../../components/TruckGallary/carosalResponsive";
import MobileGallery from "../../components/mobileview/truck-Gallary/truckGallary";
import Gallary01 from "../../components/TruckGallary/truckGallary-lightBox";
import "./Projects.css";
const Projects = () => {
  return (
    <>
      <div className="truckGallary">
        {/* <TruckGallary /> */}
        <Gallary01 />
        {/* <MobileGallery /> */}
        {/* <div className="carosal_for_mobile">
          <ResposiveCarosal />
        </div> */}
      </div>
    </>
  );
};

export default Projects;
