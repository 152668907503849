import React, { Component } from "react";
import { Divider } from "antd";

import { useTranslation } from "react-i18next";
import "../../translations/i118";

const Health_and_Safty = () => {
  const { t } = useTranslation();

  const data = {
    titleprogres: [
      {
        id: 1,
        title: t("health_safty_policy_home"),
        description: t("health_safty_description"),
      },
    ],
    flatprogress: [
      {
        id: 1,
        nameflat: "Photoshop",
        perc: "40",
        datapercent: "40",
      },
      {
        id: 2,
        nameflat: "WordPress",
        perc: "70",
        datapercent: "70",
      },
      {
        id: 3,
        nameflat: "Javascript",
        perc: "90",
        datapercent: "90",
      },
    ],
    titleAccordion: [
      {
        id: 1,
        title: "Responsibilities",
      },
    ],
    flattoggle: [
      {
        id: 1,
        toggletitle: "What are all the different types of accountants?",
        togglecontent:
          "Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch.",
        class: "toggle-title active",
      },
      {
        id: 2,
        toggletitle: "What Do Accountants Without Their CPA do?",
        togglecontent:
          "Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch.",
        class: "toggle-title",
      },
      {
        id: 3,
        toggletitle: "How do i make a mid-career switch into ?",
        togglecontent:
          "Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch.",
        class: "toggle-title",
      },
    ],
  };

  return (
    <section
      className="flat-row flat-progress-accordion bg-theme"
      style={{ backgroundColor: "white" }}
    >
      <Divider orientation="left">
        <h2
          style={{
            fontWeight: "normal",
            color: "#393E46",
            fontFamily: "Montserrat",
            fontSize: "25px",
          }}
        >
          {t("health_safty_policy_home")}
        </h2>
      </Divider>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            {data.titleprogres.map((data) => (
              <div className="progres" key={data.id}>
                <div className="progress-titl">
                  <h2>{data.title}</h2>
                  <hr />
                </div>
                <div className="progress-content">
                  <p style={{ textAlign: "start", fontSize: "17px" }}>
                    {data.description}
                  </p>
                </div>

                <p
                  style={{
                    textAlign: "start",
                    fontSize: "17px",
                    paddingTop: "15px",
                  }}
                >
                  {t("health_safty_description2")}
                </p>
                {/* {this.state.flatprogress.map((data) => (
                    <div className="flat-progress " key={data.id}>
                      <div className="name">{data.nameflat}</div>
                      <div className="perc">{data.perc}</div>
                      <div
                        className="progress-bar"
                        data-percent={data.datapercent}
                        data-waypoint-active="yes"
                      >
                        <div
                          className="progress-animate"
                          data-duration="3000"
                        ></div>
                      </div>
                    </div>
                  ))} */}
              </div>
            ))}
          </div>

          <div className="col-md-6">
            <div className="accordion">
              {/* {data.titleAccordion.map((data) => (
                <div className="accordion-title" key={data.id}>
                  <h2>{data.title}</h2>
                </div>
              ))} */}
              <div className="flat-accordion style2">
                <img
                  src="https://media.istockphoto.com/id/1349092514/photo/hse-health-safety-environment-acronym-banner-for-business-and-organization-standard-safe.jpg?s=612x612&w=0&k=20&c=Ssd46bFe0D4O6N-bWXb07xMzua4ze4JwXSyUr4_xTTU="
                  alt="safty"
                  style={{ padding: "5px" }}
                />

                {/* <ul
                  style={{
                    textAlign: "start",
                    fontSize: "medium",
                    listStyleType: "circle ",
                    padding: "20px",
                  }}
                >
                  <li>A safe working environment</li>
                  <li>Plant and substances in safe condition</li>
                  <li>
                    Information, instruction, training and supervision that is
                    reasonably necessary
                  </li>
                  <li>
                    To ensure that each worker is safe from injury and risks to
                    health
                  </li>
                  <li>
                    A commitment to consult and co-operate with workers in all
                    matters relating to health and safety in the workplace
                  </li>
                  <li>
                    A commitment to continually improve our performance through
                    effective safety management.
                  </li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Health_and_Safty;
