import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Topbar from "./components/topbar/topbar";
import Header from "./components/header/header";
import Aboutus from "./pages/AboutUs/aboutus";
import Home from "./pages/home/home";
import Services from "./pages/OurServices/services";
import Projects from "./pages/Projects/Projects";
import Contacts from "./pages/Contacts/contacts";
import MainCirtfiacte from "./components/cirtificates/cirfificatesMain";
import Footer from "./components/footer/footer";
import { useTranslation } from "react-i18next";
//footer finance

import Footer01 from "./components/footer-finance/footers/Footer";
import Footer02 from "./components/footer03/footer";

//
import Header01 from "./components/header/header.finance";
import Header02 from "./components/header/header-bootsrap/header-bootsrap";

import AboutUs from "./pages/aboutus/Overview";
import TopBarDesktop from "./components/topbar-desktop/topbar";
//mobileTopBAr

import MobileTopBAr from "./components/mobileview/topbar/topbar";
import Loader from "./components/loader/loader";
import "antd/dist/reset.css";
function App() {
  const { t, i18n } = useTranslation();

  document.body.dir = i18n.dir();

  return (
    <div className="App">
      {/* <Home /> */}
      <TopBarDesktop />
      <Topbar />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aboutus" element={<Aboutus />} />
        <Route path="/services" element={<Services />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/cirtificates" element={<MainCirtfiacte />} />
      </Routes>
      <Footer02 />
    </div>
  );
}

export default App;
