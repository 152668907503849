import React from "react";
import "./profile.css";
import { Divider } from "antd";

import { useTranslation } from "react-i18next";
import "../../../translations/i118";
import { MdOutlineEngineering } from "react-icons/md";
import { MdOutlineVisibility } from "react-icons/md";
import ProfileWithBg from "./maskedProfile";

export default function ProfileMobile() {
  const { t } = useTranslation();

  return (
    <>
      <div className="mobile-profile">
        {" "}
        <div className="d-flex flex-column mb-3">
          <div className="p-2">
            <div className="inner-div-1">
              <h2
                style={{
                  marginTop: "20px",
                  fontSize: "25px",
                }}
              >
                {t("about_us")}
              </h2>
              <hr />
              <p
                style={{
                  textAlign: "justify",
                  fontSize: "18px",
                  padding: "10x",
                  fontFamily: "Open Sans",

                  hyphens: "auto",
                }}
              >
                {t("profile_home")}
              </p>{" "}
            </div>
          </div>
          <div className="p-2">
            <MdOutlineEngineering size="55px" color="#59CE8F" />
            <h3>{t("mission")}</h3>
            <p>
              <p
                style={{
                  textAlign: "justify",
                  fontSize: "18px",
                  padding: "10px",
                  fontFamily: "Open Sans",
                }}
              >
                {t("mission_text")}
              </p>{" "}
            </p>
          </div>
          <div className="p-2">
            {" "}
            <MdOutlineVisibility size="55px" color="#59CE8F" />
            <h3>{t("vision")}</h3>
            <p>
              <p
                style={{
                  textAlign: "justify",
                  fontSize: "18px",
                  padding: "10px",
                  fontFamily: "Open Sans",
                }}
              >
                {t("vision_text")}
              </p>{" "}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
