import React from "react";
import { slide as Menu } from "react-burger-menu";
import { Link } from "react-router-dom";
import { Button } from "antd";
import LanguageSelector from "../../components/language-switcher/languageswitcher";
import i18n from "../../translations/i118";

class Example extends React.Component {
  showSettings(event) {
    event.preventDefault();
  }

  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen });
  }

  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
    };
  }

  toggleMenu() {
    this.setState((state) => ({ menuOpen: !state.menuOpen }));
  }

  closeMenu() {
    this.setState({ menuOpen: false });
  }

  handleChangeLanguage = (e) => {
    e.preventDefault();

    console.log(e.target.value);
    i18n.changeLanguage(e.target.value);
    this.closeMenu();
  };

  render() {
    const styles = {
      bmBurgerButton: {
        position: "fixed",
        width: "28px",
        height: "20px",
        left: "12px",
        top: "25px",
      },
      bmBurgerBars: {
        background: "#6ECCAF",
      },
      bmBurgerBarsHover: {
        background: "#a90000",
      },
      bmCrossButton: {
        height: "32px",
        width: "32px",
        padding: "10px",
      },
      bmCross: {
        background: "#bdc3c7",
      },
      bmMenuWrap: {
        // position: "fixed",
        height: "60%",
        marginTop: "-40px",
        marginLeft: "-20px",
        width: "70%",
      },
      bmMenu: {
        background: "white",
        // padding: "2.5em 1.5em 0",
        fontSize: "1.7em",
        opacity: "1",
        margin: "0px",
      },
      bmMorphShape: {
        // fill: ",
      },
      bmItemList: {
        color: "white",
        padding: "2em",
        textAlign: "start",
        textDecoration: "none",
        border: "1px solid white",
      },
      bmItem: {
        display: "inline-block",
        padding: "0rem",
      },
      bmOverlay: {
        background: "rgba(0, 0, 0, 0.5)",
        marginLeft: "-15px",
      },
    };

    const ButtonStyle = {
      backgroundColor: "#20c997",
      border: "1px solid white",
      color: "white",
      padding: "15px",
      textAlign: "center",
      textDecoration: "none",
      display: "inline-block",
      fontSize: "16px",
      width: "155px",
      marginBottom: "10px",
    };

    // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling
    return (
      <Menu
        styles={styles}
        pageWrapId={"page-wrap"}
        id={"sidebar"}
        className={"my-menu"}
        isOpen={this.state.menuOpen}
        onStateChange={(state) => this.handleStateChange(state)}
      >
        {/* <img className="logo" src="assets/logo1.jpeg" width="400px" alt="..." /> */}
        <div
          style={{
            gap: "2px",
            display: "flex",
            padding: "20px",
            marginLeft: "-20px",
          }}
        >
          <button
            value="ar"
            onClick={this.handleChangeLanguage}
            // style={buttonStyle}
            className="btn"
          >
            عربى
          </button>
          <button
            value="en"
            onClick={this.handleChangeLanguage}
            className="btn"
          >
            English
          </button>
        </div>

        <ul className="navbar-menu">
          <li>
            {" "}
            <Link to="/">
              {" "}
              <button style={ButtonStyle} onClick={() => this.closeMenu()}>
                Home
              </button>
            </Link>
          </li>
          <li>
            {" "}
            <Link to="aboutus">
              <button style={ButtonStyle} onClick={() => this.closeMenu()}>
                About us
              </button>
            </Link>
          </li>
          <li>
            <Link to="projects">
              <button style={ButtonStyle} onClick={() => this.closeMenu()}>
                Gallary
              </button>
            </Link>
          </li>
          <li>
            <Link to="services">
              <button style={ButtonStyle} onClick={() => this.closeMenu()}>
                Services
              </button>
            </Link>
          </li>
          <li onClick={() => this.closeMenu()}>
            <Link to="contacts">
              <button style={ButtonStyle} onClick={() => this.closeMenu()}>
                Contact
              </button>
            </Link>
          </li>

          <li>
            <Link to="cirtificates">
              <button style={ButtonStyle} onClick={() => this.closeMenu()}>
                Cirtificates
              </button>
            </Link>
          </li>
        </ul>
      </Menu>
    );
  }
}

export default Example;
