// import React from "react";

// import LightGallery from "lightgallery/react";

// import "lightgallery/css/lightgallery.css";
// import "lightgallery/css/lg-zoom.css";
// import "lightgallery/css/lg-thumbnail.css";

// import lgThumbnail from "lightgallery/plugins/thumbnail";
// import lgZoom from "lightgallery/plugins/zoom";
// // import LightGallerySettings from "lightgallery/lg-settings";
// import lgVideo from "lightgallery/plugins/video";
// import "./trucksGallary.css";
// const trucks = [
//   { url: "assets/truck/truck1.jpeg" },
//   { url: "assets/truck/truck2.jpeg" },
//   { url: "assets/truck/truck3.jpeg" },
//   { url: "assets/truck/truck4.jpeg" },
//   { url: "assets/truck/truck5.jpeg" },
// ];
// // const tanker = [
//   { url: "assets/truck/tanker1.jpeg" },
//   { url: "assets/truck/tanker2.jpeg" },
//   { url: "assets/truck/tanker3.jpeg" },
// ];
// const skip = [
//   { url: "assets/truck/trailer1.jpeg" },
//   { url: "assets/truck/trailer2.jpeg" },
// ];

// const Gallery = () => {
//   return (
//     <>
//       <div
//         style={{ marginTop: "50px", marginBottom: "100px" }}
//         className="DesktopGallary"
//       >
//         {/* <MDBContainer>
//           <MDBRow> */}
//         <div
//           style={{
//             backgroundColor: "#F7F7F7",
//             padding: "50px",
//             marginTop: "50px",
//           }}
//         >
//           <h2 style={{ fontFamily: "Josefin Sans" }}>Trucks</h2>
//           <hr />
//           <div>
//             <LightGallery
//               // onInit={onInit}
//               speed={500}
//               plugins={[lgThumbnail, lgZoom, lgVideo]}
//               mode="lg-fade"
//               controls="true"
//               height="400px"
//             >
//               {trucks.map((item) => {
//                 return (
//                   <a href={item.url}>
//                     <img
//                       alt={item.url}
//                       src={item.url}
//                       style={{
//                         height: "350px",
//                         border: "5px solid #EFF5F5",
//                         borderRadius: "25px",
//                       }}
//                     />
//                   </a>
//                 );
//               })}
//             </LightGallery>
//           </div>
//         </div>
//         <div style={{ backgroundColor: "#F7F7F7", padding: "50px" }}>
//           <h2 style={{ fontFamily: "Josefin Sans" }}>Tanker Trucks</h2>
//           <hr />
//           <div style={{ backgroundColor: "#F7F7F7", padding: "50px" }}>
//             <LightGallery
//               // onInit={onInit}
//               speed={500}
//               plugins={[lgThumbnail, lgZoom, lgVideo]}
//               mode="lg-fade"
//               controls="true"
//               height="400px"
//             >
//               {tanker.map((item) => {
//                 return (
//                   <a href={item.url}>
//                     <img
//                       alt={item.url}
//                       src={item.url}
//                       style={{
//                         height: "350px",
//                         border: "5px solid #EFF5F5",
//                         borderRadius: "25px",
//                       }}
//                     />
//                   </a>
//                 );
//               })}
//             </LightGallery>
//           </div>
//         </div>
//         <div style={{ backgroundColor: "#F7F7F7", padding: "50px" }}>
//           <h2 style={{ fontFamily: "Josefin Sans" }}>Skip Trucks</h2>
//           <hr />
//           <div style={{ backgroundColor: "#F7F7F7", padding: "50px" }}>
//             <LightGallery
//               // onInit={onInit}
//               speed={500}
//               plugins={[lgThumbnail, lgZoom, lgVideo]}
//               mode="lg-fade"
//               controls="true"
//               height="400px"
//             >
//               {skip.map((item) => {
//                 return (
//                   <a href={item.url}>
//                     <img
//                       alt={item.url}
//                       src={item.url}
//                       style={{
//                         height: "350px",
//                         border: "5px solid #EFF5F5",
//                         borderRadius: "25px",
//                       }}
//                     />
//                   </a>
//                 );
//               })}
//             </LightGallery>
//           </div>
//         </div>
//         {/* </MDBRow>
//         </MDBContainer> */}
//       </div>
//     </>
//   );
// };

// export default Gallery;

// import React, { useState, useCallback } from "react";
// import { render } from "react-dom";
// import Gallery from "react-photo-gallery";
// import Carousel, { Modal, ModalGateway } from "react-images";

// function App() {
//   const photos = [
//     {
//       src: "https://source.unsplash.com/2ShvY8Lf6l0/800x599",
//       width: 4,
//       height: 3,
//     },
//     {
//       src: "https://source.unsplash.com/Dm-qxdynoEc/800x799",
//       width: 1,
//       height: 1,
//     },
//     {
//       src: "https://source.unsplash.com/qDkso9nvCg0/600x799",
//       width: 3,
//       height: 4,
//     },
//     {
//       src: "https://source.unsplash.com/iecJiKe_RNg/600x799",
//       width: 3,
//       height: 4,
//     },
//     {
//       src: "https://source.unsplash.com/epcsn8Ed8kY/600x799",
//       width: 3,
//       height: 4,
//     },
//     {
//       src: "https://source.unsplash.com/NQSWvyVRIJk/800x599",
//       width: 4,
//       height: 3,
//     },
//     {
//       src: "https://source.unsplash.com/zh7GEuORbUw/600x799",
//       width: 3,
//       height: 4,
//     },
//     {
//       src: "https://source.unsplash.com/PpOHJezOalU/800x599",
//       width: 4,
//       height: 3,
//     },
//     {
//       src: "https://source.unsplash.com/I1ASdgphUH4/800x599",
//       width: 4,
//       height: 3,
//     },
//   ];

//   const [currentImage, setCurrentImage] = useState(0);
//   const [viewerIsOpen, setViewerIsOpen] = useState(false);

//   const openLightbox = useCallback((event, { photo, index }) => {
//     setCurrentImage(index);
//     setViewerIsOpen(true);
//   }, []);

//   const closeLightbox = () => {
//     setCurrentImage(0);
//     setViewerIsOpen(false);
//   };

//   return (
//     <div style={{ direction: "rtl" }}>
//       <Gallery photos={photos} onClick={openLightbox} />
//       <ModalGateway>
//         {viewerIsOpen ? (
//           <Modal onClose={closeLightbox}>
//             <Carousel
//               currentIndex={currentImage}
//               views={photos.map((x) => ({
//                 ...x,
//                 srcset: x.srcSet,
//                 caption: x.title,
//               }))}
//             />
//           </Modal>
//         ) : null}
//       </ModalGateway>
//     </div>
//   );
// }

// export default App;

// console.clear();
// import React from "react";
// const { Component } = React;

// const Selected = (props) => {
//   return (
//     <div
//       className={`Selected ${
//         props.phase
//           ? `Selected--${props.phase} Selected--moving-${props.direction}`
//           : ""
//       }`}
//     >
//       <div
//         className="Selected__image"
//         style={{ backgroundImage: `url(${props.src})` }}
//       />
//     </div>
//   );
// };

// const Thumb = (props) => {
//   return (
//     <div
//       className={`Thumb Thumb--offset${props.offset}`}
//       onClick={() => {
//         props.select(props.index, props.offset);
//       }}
//     >
//       <div
//         className="Thumb__inner"
//         style={{ backgroundImage: `url(${props.src})` }}
//       />
//     </div>
//   );
// };

// const positions = [-2, -1, 0, 1, 2];

// const Thumbs = (props) => {
//   return (
//     <div
//       className={`Thumbs ${
//         props.phase
//           ? `Thumbs--${props.phase} Thumbs--moving-${props.direction}`
//           : ""
//       }`}
//     >
//       {props.images.map((src, index) => {
//         const count = props.images.length;
//         let offset = index - props.active;
//         if (offset > count / 2) offset -= count;
//         else if (offset < -count / 2) offset += count;
//         return (
//           <Thumb
//             src={src}
//             key={index}
//             index={index}
//             offset={offset}
//             select={props.select}
//           />
//         );
//       })}
//     </div>
//   );
// };

// class Gallery extends Component {
//   constructor(props) {
//     super(props);
//     let loopedImages = images;
//     while (loopedImages.length < 13)
//       loopedImages = loopedImages.concat(props.images);
//     this.state = {
//       active: 0,
//       showing: 0,
//       phase: null,
//       direction: "left",
//       loopedImages,
//     };
//   }

//   componentDidMount() {
//     setTimeout(() => {
//       this.selectImage(1, 1);
//     }, 1000);
//   }

//   selectImage(index, offset) {
//     if (!this.state.phase && offset !== 0) {
//       this.setState({
//         active: index,
//         phase: "entering",
//         direction: offset >= 0 ? "left" : "right",
//       });
//       setTimeout(() => {
//         this.setState({
//           changing: false,
//           showing: index,
//           phase: "exiting",
//         });
//         setTimeout(() => {
//           this.setState({ phase: null });
//         }, 300);
//       }, 400);
//     }
//   }

//   render() {
//     return (
//       <div className="Gallery">
//         <div className="Gallery__selected">
//           <Selected
//             src={this.state.loopedImages[this.state.showing]}
//             phase={this.state.phase}
//             direction={this.state.direction}
//           />
//         </div>
//         <div className="Gallery__thumbs">
//           <Thumbs
//             images={this.state.loopedImages}
//             active={this.state.active}
//             select={this.selectImage.bind(this)}
//             phase={this.state.phase}
//             direction={this.state.direction}
//           />
//         </div>
//       </div>
//     );
//   }
// }

// const Center = (props) => {
//   return (
//     <div className="Center">
//       <div className="Center__content">{props.children}</div>
//     </div>
//   );
// };

// const images = [
//   "https://unsplash.it/1000/600?image=1069",
//   "https://unsplash.it/1000/600?image=1068",
//   "https://unsplash.it/1000/600?image=1081",
//   "https://unsplash.it/1000/600?image=855",
//   "https://unsplash.it/1000/600?image=824",
//   "https://unsplash.it/1000/600?image=655",
//   "https://unsplash.it/1000/600?image=881",
//   "https://unsplash.it/1000/600?image=314",
//   "https://unsplash.it/1000/600?image=503",
//   "https://unsplash.it/1000/600?image=447",
// ];

// class App extends Component {
//   render() {
//     return (
//       <div className="App">
//         <Center>
//           <Gallery images={images} />
//         </Center>
//       </div>
//     );
//   }
// }

// export default App;
import React from "react";
import { useState, useEffect } from "react";
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import { ThumbnailImageProps } from "react-grid-gallery";
// import "./trucksGallary.css";
// import { images, CustomImage } from "./images";

export default function App() {
  const [index, setIndex] = useState(-1);

  const [width, setWindowWidth] = useState(0);

  // componentDidMount...runs only once
  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };

  const responsive = {
    setHight: width > 1023,
  };

  const ImageComponent = (ThumbnailImageProps) => {
    return (
      <img
        {...ThumbnailImageProps.imageProps}
        alt="df"
        style={{ border: "1px solid green", objectFit: "cover" }}
      />
    );
  };

  const images = [
    {
      src: "assets/truck/truck1.jpeg",
      original: "assets/truck/truck1.jpeg",
      // sizes: ["(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"],
    },
    {
      src: "assets/truck/truck-row1.jpeg",
      original: "assets/truck/truck-row1.jpeg",
      // sizes: ["(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"],
    },
    {
      src: "assets/truck/truck-row2.jpeg",
      original: "assets/truck/truck-row2.jpeg",
      // sizes: ["(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"],
    },
    // { src: "assets/truck/truck2.jpeg", original: "assets/truck/truck2.jpeg" },
    { src: "assets/truck/truck3.jpeg", original: "assets/truck/truck3.jpeg" },
    { src: "assets/truck/truck4.jpeg", original: "assets/truck/truck4.jpeg" },
    { src: "assets/truck/truck5.jpeg", original: "assets/truck/truck5.jpeg" },
    { src: "assets/truck/tanker1.jpeg", original: "assets/truck/tanker1.jpeg" },
    { src: "assets/truck/tanker2.jpeg", original: "assets/truck/tanker2.jpeg" },
    { src: "assets/truck/tanker3.jpeg", original: "assets/truck/tanker3.jpeg" },
    {
      src: "assets/truck/trailer1.jpeg",
      original: "assets/truck/trailer1.jpeg",
    },
    {
      src: "assets/truck/trailer2.jpeg",
      original: "assets/truck/trailer2.jpeg",
    },
  ];

  const currentImage = images[index];
  const nextIndex = (index + 1) % images.length;
  const nextImage = images[nextIndex] || currentImage;
  const prevIndex = (index + images.length - 1) % images.length;
  const prevImage = images[prevIndex] || currentImage;

  const handleClick = (index, item) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  return (
    <div
      style={{
        direction: "ltr",

        backgroundColor: "",
      }}
      className="trucksContainer"
    >
      <Gallery
        images={images}
        onClick={handleClick}
        enableImageSelection={true}
        rowHeight={responsive.setHight ? 200 : 200}
        margin={responsive.setHight ? 3 : 5}
        thumbnailImageComponent={responsive.setHight ? "" : ImageComponent}
      />

      {!!currentImage && (
        /* @ts-ignore */
        <Lightbox
          mainSrc={currentImage.original}
          imageTitle={currentImage.caption}
          mainSrcThumbnail={currentImage.src}
          nextSrc={nextImage.original}
          nextSrcThumbnail={nextImage.src}
          prevSrc={prevImage.original}
          prevSrcThumbnail={prevImage.src}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      )}
    </div>
  );
}
