import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { Divider } from "antd";
import { Button } from "antd";

import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";
import "../../translations/i118";

export default function App() {
  const { t } = useTranslation();

  return (
    <>
      <Divider orientation="left">
        <h2
          style={{
            fontWeight: "normal",
            color: "#393E46",
            fontFamily: "Montserrat",
            fontSize: "25px",
          }}
        >
          {/* {t("health_safty_policy_home")} */}
          {t("award_divider")}
        </h2>
      </Divider>
      <MDBContainer>
        <MDBRow>
          {/* <MDBCol size="md">
            {" "}
      
          </MDBCol> */}
          <MDBCol size="md">
            <figure className="figure">
              <img
                src="assets/cirtificates/jrs.png"
                className="figure-img img-fluid rounded shadow-3 mb-3"
                alt="..."
                // style={{ width: "100%", height: "" }}

                style={{ width: "600px", height: "400px" }}
              />
              <figcaption className="figure-caption">
                <p style={{ fontSize: "22px" }}> {t("JSRS")}</p>
                <Link to="/cirtificates">
                  {" "}
                  <Button>{t("view_more")}</Button>{" "}
                </Link>
              </figcaption>
            </figure>
          </MDBCol>
          {/* <MDBCol size="md">
         
          </MDBCol> */}
        </MDBRow>
      </MDBContainer>
    </>
  );
}
