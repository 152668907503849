import React, { Component } from "react";
import ReactDOM from "react-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

class DemoCarousel extends Component {
  render() {
    // const image_styles = {
    //   width: "600px",
    //   height: "400px",
    //   objectFit: "cover",
    // };
    return (
      <Carousel dynamicHeight={true}>
        <div>
          <img src="assets/truck/truck1.jpeg" />
        </div>
        <div>
          <img src="assets/truck/truck2.jpeg" />
          <p className="legend">Legend 2</p>
        </div>
        <div>
          <img src="assets/truck/truck3.jpeg" />
          <p className="legend">Legend 3</p>
        </div>
      </Carousel>
    );
  }
}

export default DemoCarousel;
