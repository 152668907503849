// import React from "react";

// import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
// import { Divider } from "antd";
// import { Button } from "antd";

// import "./truckGallary.css";

// import LightGallery from "lightgallery/react";

// import "lightgallery/css/lightgallery.css";
// import "lightgallery/css/lg-zoom.css";
// import "lightgallery/css/lg-thumbnail.css";

// import lgThumbnail from "lightgallery/plugins/thumbnail";
// import lgZoom from "lightgallery/plugins/zoom";
// // import LightGallerySettings from "lightgallery/lg-settings";
// import lgVideo from "lightgallery/plugins/video";

// const TruckGallary = () => {

//   const trucks = [
//     { url: "assets/truck/truck1.jpeg" },
//     { url: "assets/truck/truck2.jpeg" },
//     { url: "assets/truck/truck3.jpeg" },
//     { url: "assets/truck/truck4.jpeg" },
//     { url: "assets/truck/truck5.jpeg" },
//   ];
//   const tanker = [
//     { url: "assets/truck/tanker1.jpeg" },
//     { url: "assets/truck/tanker2.jpeg" },
//   ];
//   const trailer = [
//     { url: "assets/truck/trailer1.jpeg" },
//     { url: "assets/truck/trailer2.jpeg" },
//   ];

//   return (
//     <>
//       <div
//         style={{ marginTop: "100px", marginBottom: "100px" }}
//         className="mobileTruckGallary"
//       >
//         {/* <MDBContainer>
//           <MDBRow> */}
//         <div>
//           <h3>TRUCKS</h3>
//           <Divider />
//           <LightGallery
//             // onInit={onInit}
//             speed={500}
//             plugins={[lgThumbnail, lgZoom, lgVideo]}
//             mode="lg-fade"
//             controls="true"
//             height="400px"
//           >
//             {trucks.map((item) => {
//               return (
//                 <a href={item.url}>
//                   <img
//                     alt="assets/cirtificates/jrs.png"
//                     src={item.url}
//                     style={{
//                       height: "400px",
//                       width: "100%",
//                       border: "5px solid #EFF5F5",
//                       borderRadius: "5px",
//                       padding: "15px",
//                       margin: "5px",
//                       objectFit: "cover",
//                     }}
//                   />
//                 </a>
//               );
//             })}
//           </LightGallery>
//         </div>
//         <div>
//           <h3>Tanker</h3>
//           <Divider />
//           <LightGallery
//             // onInit={onInit}
//             speed={500}
//             plugins={[lgThumbnail, lgZoom, lgVideo]}
//             mode="lg-fade"
//             controls="true"
//             height="400px"
//           >
//             {tanker.map((item) => {
//               return (
//                 <a href={item.url}>
//                   <img
//                     alt="assets/cirtificates/jrs.png"
//                     src={item.url}
//                     style={{
//                       height: "400px",
//                       width: "100%",
//                       border: "5px solid #EFF5F5",
//                       borderRadius: "5px",
//                       padding: "15px",
//                       margin: "5px",
//                       objectFit: "cover",
//                     }}
//                   />
//                 </a>
//               );
//             })}
//           </LightGallery>
//         </div>
//         <div>
//           <h3>Skip Trucks</h3>
//           <Divider />
//           <LightGallery
//             // onInit={onInit}
//             speed={500}
//             plugins={[lgThumbnail, lgZoom, lgVideo]}
//             mode="lg-fade"
//             controls="true"
//             height="400px"
//           >
//             {trailer.map((item) => {
//               return (
//                 <a href={item.url}>
//                   <img
//                     alt="assets/cirtificates/jrs.png"
//                     src={item.url}
//                     style={{
//                       height: "400px",
//                       width: "100%",
//                       border: "5px solid #EFF5F5",
//                       borderRadius: "5px",
//                       padding: "15px",
//                       margin: "5px",
//                       objectFit: "cover",
//                     }}
//                   />
//                 </a>
//               );
//             })}
//           </LightGallery>
//         </div>
//         {/* </MDBRow>
//         </MDBContainer> */}
//       </div>
//     </>
//   );
// };

// export default TruckGallary;

import React, { useState, useCallback } from "react";
import { render } from "react-dom";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

function App() {
  const photos = [
    {
      src: "https://source.unsplash.com/2ShvY8Lf6l0/800x599",
      width: 4,
      height: 3,
    },
    {
      src: "https://source.unsplash.com/Dm-qxdynoEc/800x799",
      width: 1,
      height: 1,
    },
    {
      src: "https://source.unsplash.com/qDkso9nvCg0/600x799",
      width: 3,
      height: 4,
    },
    {
      src: "https://source.unsplash.com/iecJiKe_RNg/600x799",
      width: 3,
      height: 4,
    },
    {
      src: "https://source.unsplash.com/epcsn8Ed8kY/600x799",
      width: 3,
      height: 4,
    },
    {
      src: "https://source.unsplash.com/NQSWvyVRIJk/800x599",
      width: 4,
      height: 3,
    },
    {
      src: "https://source.unsplash.com/zh7GEuORbUw/600x799",
      width: 3,
      height: 4,
    },
    {
      src: "https://source.unsplash.com/PpOHJezOalU/800x599",
      width: 4,
      height: 3,
    },
    {
      src: "https://source.unsplash.com/I1ASdgphUH4/800x599",
      width: 4,
      height: 3,
    },
  ];

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <div>
      <Gallery photos={photos} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
}

export default App;
