import React, { Component } from "react";
// import BlogFeatured from "./BlogFeatured";
// import BlogSlidebar from "./BlogSlidebar";
class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      titleNews: [
        {
          id: 1,
          title: "Message from Chairman",
        },
      ],
    };
  }

  render() {
    return (
      <section
        className="flat-row flat-news"
        style={{ backgroundColor: "black" }}
      >
        <div className="container" style={{ backgroundColor: "black" }}>
          <div className="row">
            <div className="col-md-12">
              <div className="title-section left">
                {this.state.titleNews.map((data) => (
                  <h2 key={data.id}>{data.title}</h2>
                ))}
              </div>
              {/* <!-- /.title-section --> */}
            </div>
          </div>
          {/* <!-- /.row --> */}

          <div
            className="row"
            style={{ display: "flex", backgroundColor: "black" }}
          >
            <div style={{ display: "flex", backgroundColor: "black" }}>
              <img
                src="assets/arbab-img.jpeg"
                alt="hello"
                style={{ width: "300px", height: "400px" }}
              />
              <p
                style={{
                  textAlign: "start",
                  fontSize: "15px",
                  padding: "40px",
                  fontWeight: "bold",
                  size: "18px",
                  marginTop: "40px",
                }}
              >
                " Our objective is to provide our clients with an “I am assured”
                experience when we are chosen to execute their projects. Our
                emphasis on clear communication and follow-through procedures
                ensures that client’s objectives are top priority in the
                planning and execution of all our processes"- <br />
                <span
                  style={{
                    fontSize: "18px",

                    float: "right",
                  }}
                >
                  {" "}
                  Mohammed Mabakhut{" "}
                </span>
                <br />
                <span
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    float: "right",
                    marginTop: "10px",
                  }}
                >
                  {" "}
                  General Manager
                </span>
              </p>
            </div>
          </div>

          {/* <!-- /.row --> */}
        </div>
        {/* <!-- /.container --> */}
      </section>
    );
  }
}

export default Blog;
