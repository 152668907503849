import React from "react";

import "./image-overlay.css";
import { useTranslation } from "react-i18next";
import "../../../translations/i118";

const ImageOverlay = () => {
  const { t } = useTranslation();
  const h1Style = {
    color: "white",
    fontSize: "30px",
    marginTop: "100px",
    border: " 3px solid white",
    width: "50%",
    marginLeft: "25%",
    backgroundColor: "rgba(36, 43, 59,.8)",
  };
  const paragraphStyle = {
    textAlign: "justify",
    color: "white",
    fontSize: "16px",
    padding: "20px",
    backgroundColor: "rgba(36, 43, 59,.8)",
    fontFamily: "Montserrat",
    fontWeight: "500",
  };

  return (
    <>
      <div className="bg-image">
        <img
          src="assets/stock-img/img2.jpg"
          className="img-fluid"
          alt="Sample"
          style={{
            height: "100vh",
            objectFit: "cover",
          }}
        />
        <div
          className="mask"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", marginTop: "100px" }}
        >
          <h1 style={h1Style}>{t("about_us")}</h1>
          <hr style={{ color: "white" }} />
          <p className="text-white mb-0" style={paragraphStyle}>
            {" "}
            {t("profile_home")}
          </p>
        </div>
      </div>
    </>
  );
};

export default ImageOverlay;
