import React from "react";
import { Divider } from "antd";

import { useTranslation } from "react-i18next";
import "../../../translations/i118";
import Table from "../../equipment-table/table";
import ImageOverlay from "./image-overlay";
import Safty from "../../safty-policy/safty-policy";
const AboutUs = () => {
  const { t } = useTranslation();

  const paragraph_style = {
    textAlign: "start",
  };
  return (
    <>
      <div className="mobile-profile">
        {" "}
        <div className="d-flex flex-column mb-3">
          <div className="p-2">
            <ImageOverlay />
          </div>
          <div className="p-2">
            {/* <MdOutlineEngineering size="55px" color="green" /> */}
            <h1 style={{ border: "2px solid black", letterSpacing: "5px" }}>
              {t("mission")}
            </h1>
            <p>
              <p
                style={{
                  textAlign: "justify",
                  fontSize: "18px",
                  padding: "2px",
                  backgroundColor: "rgba(36, 43, 59,.8)",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                {t("mission_text")}
              </p>{" "}
            </p>
          </div>
          <div className="p-2">
            {" "}
            <h1 style={{ border: "2px solid black", letterSpacing: "5px" }}>
              {t("vision")}
            </h1>
            <p>
              <p
                style={{
                  textAlign: "justify",
                  fontSize: "18px",
                  padding: "2px",

                  backgroundColor: "rgba(36, 43, 59,.8)",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                {t("vision_text")}
              </p>{" "}
            </p>
          </div>
          <div className="p-2">
            <Table />
          </div>
          <Safty />
        </div>
      </div>
    </>
  );
};

export default AboutUs;
