import React from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardImage,
  MDBBtn,
  MDBRipple,
} from "mdb-react-ui-kit";

import { MDBIcon } from "mdb-react-ui-kit";

export default function App(props) {
  return (
    <MDBCard style={{ height: "250px" }}>
      <div className="bg-image hover-zoom">
        <MDBIcon
          fas
          icon={props.icon}
          style={{
            marginTop: "10px",
            color: "#59CE8F",
            fontSize: "25px",
            padding: "5px",
          }}
        />
      </div>
      <MDBCardBody>
        <MDBCardTitle style={{ fontSize: "25px", fontWeight: "bold" }}>
          {props.title}
        </MDBCardTitle>

        <MDBCardText style={{ padding: "20px", fontSize: "19px" }}>
          {props.text}
        </MDBCardText>
      </MDBCardBody>
    </MDBCard>
  );
}
