import React, { Component } from "react";
import { Link } from "react-router-dom";

import { MdOutlineEngineering } from "react-icons/md";
import { GiTruck } from "react-icons/gi";
import { GiFactory } from "react-icons/gi";

class ServicesBoxs1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataEvents: [
        {
          id: 1,
          classicon: <MdOutlineEngineering size="55px" />,
          title: "Project Management & Construction",
          description:
            "AL BADIA GREEN OASIS TRADING EST. can take on the role of main contractor for small to medium size projects and performs project management services. ",
        },
        {
          id: 2,
          classicon: <GiTruck size="65px" />,
          title: "Rental of equipment",
          description:
            "Donec lacinia mi rutrum sagittis consequat. Donec sagittis, tellus sodales sollicitudin commodo",
        },
        {
          id: 3,
          classicon: <GiFactory size="65px" />,
          title: "Water treatment plant",
          description:
            "AL BADIA GREEN OASIS TRADING EST”, established a water treatment plant as an additional service to provide drinking water for our valued Customers.",
        },
      ],
    };
  }

  render() {
    return (
      <div className="row">
        {this.state.dataEvents.map((data) => (
          <div className="col-md-4" key={data.id}>
            <div className="iconbox-item">
              <div className="iconbox style1">
                <div className="box-header">
                  {/* <div className={data.classicon}></div> */}
                  <div className="icon-44331">{data.classicon}</div>

                  <div className="box-title">
                    <Link to="#" title="">
                      {data.title}
                    </Link>
                  </div>
                  {/* <!-- /.box-title --> */}
                </div>
                {/* <!-- /.box-header --> */}
                <div className="box-content">{data.description}</div>
                {/* <!-- /.box-content --> */}
              </div>
              {/* <!-- /.iconbox --> */}
            </div>
            {/* <!-- /.iconbox-item --> */}
          </div>
        ))}
      </div>
    );
  }
}

export default ServicesBoxs1;
