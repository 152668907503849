import React, { Component, useRef, useState } from "react";
// import { Header, TopBar, Footer, Loader } from "../layouts/general";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../translations/i118";
import GoogleMap from "../../components/google-map/map";
import emailjs from "@emailjs/browser";
import { Alert, Space } from "antd";

const Contact = () => {
  const { t } = useTranslation();
  const form = useRef();

  const [visible, setVisible] = useState(false);

  const handleClose = () => {
    setVisible(false);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_u4uzy4g", "albadia_256", e.target, "4Sx0x47g43sdHhrr1")
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    // console.log(e.target);
    e.target.reset();
    setVisible(true);
  };

  const renderAlert = () => {
    return (
      <Space
        direction="vertical"
        style={{
          width: "25%",
          position: "absolute",
          zIndex: "9999",
          marginTop: "60px",
        }}
      >
        <Alert
          message="Thank You"
          description="We will Contact you soon"
          type="success"
          showIcon
          closable
          afterClose={handleClose}
        />
      </Space>
    );
  };

  const data = {
    headers: [
      {
        id: 1,
        names: "Blog",
      },
    ],
    titleheading: [
      {
        id: "1",
        title: "Contact",
      },
    ],
    breadcrumbs: [
      {
        id: 1,
        title: "Home",
        classicon: "fa fa-angle-right",
        aria: "true",
      },
      {
        id: 2,
        title: "Contact",
        classicon: "",
        aria: "",
      },
    ],
    contactinfo: [
      {
        id: "1",
        title: t("address"),
        info: t("address_text"),
      },
      {
        id: "2",
        title: t("phone"),
        info: "+968 91160006",
      },
      {
        id: "3",
        title: t("email"),
        info: "oman91160006@gmail.com",
      },
    ],
  };

  return (
    <>
      <div className="bg-body">
        <div className="boxed">
          <div className="page-title">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="page-title-heading">
                    {data.titleheading.map((data) => (
                      <h1 key={data.id} className="h1-title">
                        {data.title}
                      </h1>
                    ))}
                  </div>
                  <ul className="breadcrumbs">
                    {data.breadcrumbs.map((data) => (
                      <li key={data.id}>
                        <Link to="#" title="">
                          {data.title}
                          <i
                            className={data.classicon}
                            aria-hidden={data.aria}
                          ></i>
                        </Link>
                      </li>
                    ))}
                  </ul>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
          </div>

          <section className="flat-row pd-contact-v1">
            <div className="container">
              {visible && renderAlert()}
              <div className="row">
                <div className="col-md-4">
                  <div className="contact-info">
                    {data.contactinfo.map((data) => (
                      <div className="info info-address" key={data.id}>
                        <div className="title" style={{ textAlign: "start" }}>
                          {data.title}
                        </div>
                        <p style={{ textAlign: "start", fontSize: "15px" }}>
                          {data.info}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="col-md-8">
                  <div className="flat-form-info">
                    <form
                      onSubmit={sendEmail}
                      id="contactform"
                      className="form-info"
                    >
                      <div className="one-half v3">
                        <p className="input-info">
                          <input
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Name"
                            required="required"
                          />
                        </p>
                        <p className="input-info">
                          <input
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Your Email"
                            required="required"
                          />
                        </p>
                        <p className="input-info">
                          <input
                            type="text"
                            name="subject"
                            id="subject"
                            placeholder="Subject"
                            required="required"
                          />
                        </p>
                        <p className="input-info">
                          <button type="submit" className="submit">
                            Send Message
                          </button>
                        </p>
                      </div>
                      <div className="one-half v4">
                        <p className="input-info">
                          <textarea
                            id="message-contact"
                            name="message"
                            placeholder="Message"
                            required="required"
                            style={{
                              fontSize: "18px",
                              color: "rgba(0,0,0,0,.5)",
                            }}
                          ></textarea>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="flat-row pdmap">
            <div
              className="flat-maps"
              data-address="Thành phố New York, Tiểu bang New York"
              data-height="454"
              data-images="images/map/map-1.png"
              data-name="Themesflat Map"
            ></div>
            <div className="gm-map">
              <div className="map">
                <GoogleMap />
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Contact;
