import React from "react";
import "./chairmansMessage.css";
import { useTranslation } from "react-i18next";
import "../../../translations/i118";
import { Divider } from "antd";

const ChairmansMessage = () => {
  const { t } = useTranslation();

  const imageStyles = {
    height: "400px",
    padding: "10px",
    border: "10px solid gray",
    borderRadius: "5px",
  };
  return (
    <div className="chairmansmessage-mobile">
      <div className="d-flex flex-column mb-3">
        <div className="p-2">
          <h2>{t("message_from_chairman")}</h2>
          <Divider />
          <img src="assets/arbab-img.jpeg" alt="" style={imageStyles} />
          <h3 style={{ marginTop: "15px" }}>{t("shaike_name")}</h3>
          <p>{t("shaike_designation")}</p>
        </div>
        <div className="p-2">
          <p>
            <p
              style={{
                textAlign: "justify",
                fontSize: "18px",
                padding: "20px",
                fontWeight: "bold",
                // fontStyle: "italic",
                color: "gray",
              }}
            >
              {t("mission_text")}
            </p>{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ChairmansMessage;
